import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { AuthService } from '../shared/auth.service';
import { Helper } from '../shared/helper';
import { ThrowStmt } from '@angular/compiler';

@Injectable()
export class ResInterceptInterceptor implements HttpInterceptor {



  constructor(private _helper:Helper,
    private _auth:AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

      return next.handle(req).pipe(tap(evt => {
          if (evt instanceof HttpResponse && evt.body) {
            if (evt.body.success) {
              this._helper.onSuccess(evt.body.message)
            } else {
              if(evt.body.error_code === 999){
                this._auth.signOut()
              }

              if(evt.body.stripe_error){
                this._helper.onError(evt.body.error_code);
              }else{
                if(evt.body.error_code != 634 && evt.body.error_code != 892){
                  this._helper.onError(evt.body.error_code);
                }
              }
            }
          }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this._helper.onError('2003');
        }
        return of(err);
      }));

  }

  // onError(errorMessage): void {
  //   errorMessage = string_json.default['error-code'][errorMessage] ? errorMessage : '2003';
  //   errorMessage = this.translate.instant('error-code.' + errorMessage)
  //   this.notifications.create(
  //     this.translate.instant('alert.error'),
  //     errorMessage,
  //     NotificationType.Error,
  //     this.toastConfig
  //   );
  // }

  // onSuccess(successCode): void {
  //   if (string_json.default['success-code'][successCode]) {
  //     this.notifications.create(
  //       this.translate.instant('alert.success'),
  //       this.translate.instant('success-code.' + successCode),
  //       NotificationType.Success,
  //       this.toastConfig
  //     );
  //   }
  // }
}
