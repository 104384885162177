import { roLocale } from 'ngx-bootstrap/chronos';
import { environment } from 'src/environments/environment';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  role?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: string[];
}

const data: IMenuItem[] = [
// {
//   icon: 'iconsminds-shop-4',
//   label: 'menu-title.dashboard',
//   to: `${adminRoot}/order/dashboard`
// },
  {
    icon: 'simple-icon-people',
    label: 'Info & Set-Up',
    to: `${adminRoot}/info`,
    subs: [
      // {
      //   icon: 'simple-icon-layers',
      //   label: 'Promotional Materials',
      //   to: `${adminRoot}/info/promotional`
      // },
      {
        icon: 'simple-icon-layers',
        label: 'Welcome Video',
        to: `${adminRoot}/info/welcome-video`
      },
      {
        icon: 'simple-icon-layers',
        label: 'Merchant Portal Set-up',
        to: `${adminRoot}/merchant`,
        subs: [
          {
            icon: '',
            label: 'Your Profile & Hero Image',
            to: `${adminRoot}/info/restaurant-account`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'Your Settings',
            to: `${adminRoot}/info/your-setting`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'Create Your Menu',
            to: `${adminRoot}/info/create-your-menu`,
            role: 'store/setting'
          }
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'Merchant App Set-up',
        to: `${adminRoot}/getting`,
        subs: [
          {
            icon: '',
            label: 'Logins for Merchant App',
            to: `${adminRoot}/info/team`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'Merchant App Install',
            to: `${adminRoot}/info/merchant-app-install`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'Printer Set-Up',
            to: `${adminRoot}/info/printer-set-up`,
            role: 'store/setting'
          }, {
            icon: '',
            label: 'Merchant App Features',
            to: `${adminRoot}/info/merchant-app`,
            role: 'store/setting'
          }
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'Onboarding Drivers & Customer Marketing',
        to: `${adminRoot}/onboarding`,
        subs: [
          {
            icon: '',
            label: 'Onboard Your Favourite Drivers',
            to: `${adminRoot}/info/your-rolla`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'Marketing to your Customers',
            to: `${adminRoot}/info/marketing-customers`,
            role: 'store/setting'
          }
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'Need to Know',
        to: `${adminRoot}/need`,
        subs: [
          {
            icon: '',
            label: 'Driver Rates',
            to: `${adminRoot}/info/driver-rates`,
            role: 'store/setting'
          }, {
            icon: '',
            label: 'Driver Payments',
            to: `${adminRoot}/info/driver-payments`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'Allocating Deliveries to Drivers',
            to: `${adminRoot}/info/allocating-deliveries`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'Managing Driver Issues',
            to: `${adminRoot}/info/managing-driver-issues`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'In-house Deliveries',
            to: `${adminRoot}/info/using-own-drivers`,
            role: 'store/setting'
          },{
            icon: '',
            label: 'Customer Service & Refunds',
            to: `${adminRoot}/info/customer-refunds`,
            role: 'store/setting'
          }, 
          {
            icon: '',
            label: 'Customer Data',
            to: `${adminRoot}/info/customer-data`,
            role: 'store/setting'
          }, 
          {
            icon: '',
            label: 'Cancelling Orders',
            to: `${adminRoot}/info/cancelling-order`,
            role: 'store/setting'
          }
        ],
      },
    ],
  },

    {
    icon: 'simple-icon-layers',
    label: 'Tools',
    to: `${adminRoot}/tools`,
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'Promotional Materials',
        to: `${adminRoot}/info/promotional`
      },
      {
        icon: 'simple-icon-layers',
        label: 'Digital Assets',
        to: `${adminRoot}/info/digital-assets`
      }
    ],
  },
  
  {
    icon: 'simple-icon-settings',
    label: 'menu-title.settings',
    to: `${adminRoot}/settings`,
    subs: [
      {
        icon: 'simple-icon-settings',
        label: 'menu-title.store-settings',
        to: `${adminRoot}/settings/store-settings`,
        role:'store/setting'
      },
      {
        icon: 'iconsminds-scooter',
        label: 'menu-title.delivery-settings',
        to: `${adminRoot}/settings/delivery-settings`,
        role:'store/deliveries'
      },{
        icon: 'iconsminds-clock',
        label: 'menu-title.busy-period-settings',
        to: `${adminRoot}/settings/busy-period-settings`,
        role:'store/store_time_setting'
      },
      {
        icon: 'iconsminds-clock',
        label: 'menu-title.store-open-close-settings',
        to: `${adminRoot}/settings/store-open-close-settings`,
        role:'store/store_time_setting'
      },{
        icon: 'iconsminds-clock',
        label: 'menu-title.prep-time-settings',
        to: `${adminRoot}/settings/prep-time-settings`,
        role:'store/prep_time_setting'
      },
      // {
      //   icon: 'iconsminds-mail-settings',
      //   label: 'menu-title.email-settings',
      //   to: `${adminRoot}/settings/email-settings`,
      //   role:'store/email'
      // },
      // {
      //   icon: 'simple-icon-speech',
      //   label: 'menu-title.sms-settings',
      //   to: `${adminRoot}/settings/sms-settings`,
      //   role:'store/sms'
      // },
      // {
      //   icon: 'simple-icon-key',
      //   label: 'menu-title.google-key-settings',
      //   to: `${adminRoot}/settings/google-key-settings`,
      //   role:'store/google_key_setting'
      // },
      // {
      //   icon: 'simple-icon-paypal',
      //   label: 'menu-title.payment-gateway-settings',
      //   to: `${adminRoot}/settings/payment-gateway-settings`,
      //   role:'store/payment_gateways',
      // },
      // {
      //   icon: 'iconsminds-gears',
      //   label: 'menu-title.user-website-settings',
      //   to: `${adminRoot}/settings/user-website-settings`,
      //   role:'store/user_website_setting'
      // },
      // {
      //   icon: 'simple-icon-credit-card',
      //   label: 'menu-title.payment',
      //   to: `${adminRoot}/settings/payment`,
      //   role:'store/payments'
      // },
    ],
  },
  {
    icon: 'iconsminds-pantone',
    label: 'menu-title.menu',
    to: `${adminRoot}/menu`,
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.menu',
        to: `${adminRoot}/menu/levels1`,
        subs: [
          {
            icon: 'simple-icon-film',
            label: 'menu-title.item',
            to: `${adminRoot}/menu/item-list`,
            role:'store/product'
          },
          /*{
            icon: 'simple-icon-organization',
            label: 'menu-title.category',
            to: `${adminRoot}/menu/category`,
            role:'store/group'
          }*/
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.modifier',
        to: `${adminRoot}/menu/levels2`,
        subs: [
          {
            icon: 'simple-icon-list',
            label: 'menu-title.modifier-group',
            to: `${adminRoot}/menu/modifier-group`,
            role:'store/modifier_group'
          }
        ],
      },
    ],
  },
  {
    icon: 'simple-icon-people',
    label: 'menu-title.users',
    to: `${adminRoot}/users`,
    subs: [
      // {
      //   icon: 'iconsminds-male-female',
      //   label: 'menu-title.user',
      //   to: `${adminRoot}/users/user`,
      //   role:'store/users'
      // },
      {
        icon: 'iconsminds-mens',
        label: 'menu-title.delivery-boy-list',
        to: `${adminRoot}/users/delivery-boy-list`,
        role:'store/providers'
      },
      {
        icon: 'iconsminds-clothing-store',
        label: 'menu-title.sub-stores',
        to: `${adminRoot}/users/sub-stores`,
        role:'store/sub_store'
      }
    ],
  },
  {
    icon: 'simple-icon-notebook',
    label: 'menu-title.order',
    to: `${adminRoot}`,
    subs: [
      /*{
        icon: 'iconsminds-shopping-cart',
        label: 'menu-title.create-order',
        to: `${adminRoot}/create-order`,
        role:'store/create_order',
      },*/
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.running-order',
        to: `${adminRoot}/order`,
        subs: [
          {
            icon: 'iconsminds-receipt-4',
            label: 'menu-title.past-order',
            to: `${adminRoot}/order/past-orders`,
            role:'store/order'
          },/*
          {
            icon: 'simple-icon-book-open',
            label: 'menu-title.today-order',
            to: `${adminRoot}/order/today-orders`,
            role:'store/order'
          },
          {
            icon: 'iconsminds-sand-watch-2',
            label: 'menu-title.tomorrow-order',
            to: `${adminRoot}/order/tomorrow-orders`,
            role:'store/order'
          },
          {
            icon: 'iconsminds-over-time-2',
            label: 'menu-title.schedule-order',
            to: `${adminRoot}/order/schedule-orders`,
            role:'store/order'
          },
          {
            icon: 'iconsminds-checkout',
            label: 'menu-title.dispatch',
            to: `${adminRoot}/order/dispatch`,
            role:'store/dispatcher'
          },*/
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.history',
        to: `${adminRoot}/history`,
        subs: [
         /* {
            icon: 'simple-icon-calendar',
            label: 'menu-title.current-week',
            to: `${adminRoot}/history/current-week`,
            role:'store/history'
          },
          {
            icon: 'iconsminds-calendar-4',
            label: 'menu-title.current-month',
            to: `${adminRoot}/history/current-month`,
            role:'store/history'
          },*/
          {
            icon: 'iconsminds-receipt-4',
            label: 'menu-title.history1',
            to: `${adminRoot}/history/history-list`,
            role:'store/history'
          },
          /*{
            icon: 'simple-icon-star',
            label: 'menu-title.reviews',
            to: `${adminRoot}/history/reviews`,
            role:'store/review'
          },*/
        ],
      },
    ],
  },
  
  {
    icon: 'iconsminds-coins',
    label: 'menu-title.earning',
    to: `${adminRoot}/earning`,
    subs: [
      {
        icon: 'simple-icon-calendar',
        label: 'menu-title.current-week',
        to: `${adminRoot}/earning/current-week`,
        role:'store/weekly_earning'
      }, {
        icon: 'iconsminds-calendar-4',
        label: 'menu-title.current-month',
        to: `${adminRoot}/earning/current-month`,
        role:'store/earning'
      }, {
        icon: 'simple-icon-grid',
        label: 'menu-title.earning1',
        to: `${adminRoot}/earning/earnings`,
        role:'store/earning'
      },
      // {
      //   icon: 'simple-icon-picture',
      //   label: 'menu-title.report',
      //   to: `${adminRoot}/vien/start`,
      //   role:'store/earning'
      // },
    ],
  },

  {
    icon: 'simple-icon-docs',
    label: 'menu-title.reports',
    to: `${adminRoot}/reports`,
    subs: [
      {
        icon: 'simple-icon-doc',
        label: 'menu-title.product-sales-mix',
        to: `${adminRoot}/reports/product-sales-mix`,
        role:'store/product_sales_mix'
      }
    ],
  },

  {
    icon: 'simple-icon-star',
    label: 'menu-title.reviews',
    to: `${adminRoot}/history/reviews`,
    role:'store/review'
  },
  
  // {
  //   icon: 'iconsminds-clothing-store',
  //   label: 'menu-title.sub-stores',
  //   to: `${adminRoot}/sub-stores/list`,
  //   role:'store/sub_store'
  // },
  /*{
    icon: 'simple-icon-tag',
    label: 'menu-title.offers',
    to: `${adminRoot}/offers`,
    role:'store/promocode'
  },*/
  
  // {
  //   icon: 'simple-icon-tag',
  //   label: 'menu-title.offers',
  //   to: `${adminRoot}/offers`,
  //   subs: [
  //     {
  //       icon: 'iconsminds-financial',
  //       label: 'menu-title.offer',
  //       to: `${adminRoot}/offers/offer`,
  //     }
  //   ]
  // },
  
  // {
  //   icon: 'iconsminds-library',
  //   label: 'menu-title.profile',
  //   to: `${adminRoot}/profile`,
  //   role:'store/profile'
  // },
  // {
  //   icon: 'iconsminds-library',
  //   label: 'menu-title.profile',
  //   to: 'https://vien-docs.coloredstrategies.com/',
  //   newWindow: true,
  // },
];

// const data: IMenuItem[] = [
//   {
//     icon: 'iconsminds-air-balloon-1',
//     label: 'menu.vien',
//     to: `${adminRoot}/vien`,
//     subs: [
//       {
//         icon: 'simple-icon-paper-plane',
//         label: 'menu.start',
//         to: `${adminRoot}/vien/start`,
//       },
//     ],
//   },
//   {
//     icon: 'iconsminds-three-arrow-fork',
//     label: 'menu.second-menu',
//     to: `${adminRoot}/second-menu`,
//     subs: [
//       {
//         icon: 'simple-icon-paper-plane',
//         label: 'menu.second',
//         to: `${adminRoot}/second-menu/second`,
//       },
//     ],
//   },
//   {
//     icon: 'simple-icon-layers',
//     label: 'menu.store-menu',
//     to: `${adminRoot}/menu`,
//     subs: [
//       {
//         icon: 'simple-icon-organization',
//         label: 'menu.store-menu-category',
//         to: `${adminRoot}/menu/category`,
//       },
//       {
//         icon: 'simple-icon-menu',
//         label: 'menu.store-menu-item-list',
//         to: `${adminRoot}/menu/item-list`,
//       },
//       {
//         icon: 'simple-icon-list',
//         label: 'menu.store-menu-modifier-group',
//         to: `${adminRoot}/menu/modifier-group`,
//       }
//     ],
//   },
//   {
//     icon: 'iconsminds-bucket',
//     label: 'menu.blank-page',
//     to: `${adminRoot}/blank-page`,
//   },
//   {
//     icon: 'iconsminds-library',
//     label: 'menu.docs',
//     to: 'https://vien-docs.coloredstrategies.com/',
//     newWindow: true,
//   },
// ];
export default data;
