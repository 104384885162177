import { Injectable, NgZone } from '@angular/core';
import { METHODS } from './http_methods';
import { GET_METHOD } from './http_methods'
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_IMAGE_PATH, ORDER_STATE } from 'src/app/views/app/constant'
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as string_json from 'src/assets/i18n/en.json'
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '../constants/constant';
import { log } from 'console';

export interface IResponse {
   success: boolean,
   message: string,
   data: any
}

@Injectable({ providedIn: "root" })
export class Helper {

  base_url = CONFIG.apiUrl;
  IMAGE_URL = CONFIG.imageUrl;
  public POST_METHOD = METHODS;
  public METHODS = METHODS;
  public NotificationType = NotificationType;
  public GET_METHOD = GET_METHOD
  public ORDER_STATE = ORDER_STATE;
  public DEFAULT_IMAGE_PATH = DEFAULT_IMAGE_PATH;
  public height;
  public width;
  public is_loading;
  toastConfig = { timeOut: 3000, showProgressBar: true };

  constructor(public http: HttpClient,public router: Router,public ngZone:NgZone,public notifications: NotificationsService,private translate: TranslateService) { }

  dashboard_http_post_method_requester(api_name, parameter) {
      return this.http.post<any>(environment.dashboardUrl + api_name, parameter).toPromise()
  }
  http_post_method_requester(api_name, parameter,is_loader = false,header={}) {
    if(is_loader){
      this.is_loading = true;
    }
    return this.http.post<any>(this.base_url + api_name, parameter).toPromise().then(data=>{
      setTimeout(() => {
        if(this.is_loading){
          this.is_loading = false;
        }
      }, 1000);
      return data;
    })
  }
  row_post_method_requester(api_name, parameter, is_loader = false, header = {}
    ) {
    if (is_loader) {
      this.is_loading = true;
    }
    return this.http.post<any>(api_name, parameter, header).toPromise().then(data => {
      console.log(data)
      setTimeout(() => {
        if (this.is_loading) {
          this.is_loading = false;
        }
      }, 1000);
      return data;
    })
  }

  http_get_method_requester(api_name, parameter) {
    return this.http.get<any>(this.base_url + api_name + '?' + 'store_id=' + parameter).toPromise()
  }

  phone_number_validation(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101) {
      return false;
    }
    return true;
  }

  number_validation(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 46) {
        return true;
      }
      else {
        return false;
      }
    }
    return true;
  }

  space_validation(evt){
    if(evt.code == "Space" && evt.target.value.length < 1){
      return false;
    }
    return true
  }


  export_csv(data, fieldname,filename='data', is_show_sr_no = true, header = null, footer = null) {

    var date_name:any = new Date().toLocaleDateString();
    date_name.replaceAll("/","_");
    filename = filename + '_' + date_name;
    let csvData = "";
    if(header){
      csvData = this.convert_to_csv_header(header);
    }
    console.log(csvData);
    csvData += this.convert_to_csv(data, fieldname, is_show_sr_no);
    console.log(csvData);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  private convert_to_csv(objArray, headerList, is_show_sr_no) {
    console.log(objArray)
    console.log(headerList)
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    if(is_show_sr_no){
      row = 'SR NO,';
    }

    for (let index in headerList) {
      let data = headerList[index];
      if(headerList[index] == "user_rating_to_store"){
        data = "Restaurant Review";
      }
      if(headerList[index] == "user_rating_to_provider"){
        data = "Driver Review";
      }
      if(headerList[index] == "user_review_to_store"){
        data = "Restaurant Comment";
      }
      if(headerList[index] == "user_review_to_provider"){
        data = "Driver Comment";
      }
      var dump = data.replaceAll("_"," ")
      row += dump.toUpperCase() + ',';
    }

    row = row.slice(0, -1);
    str += row + '\r\n';


    console.log("array")
    console.log(array)
    console.log(str)
    for (let i = 0; i < array.length; i++) {
        console.log("*****************")

        let line = '';
        if(is_show_sr_no){
          line += (i+1)+',';
        }

        for (let index in headerList) {
            let head = headerList[index];
            if(array[i][head] && array[i][head].toString().includes(",")){
              line += array[i][head].toString().replaceAll(',','') + ',';
            }else{
              line += array[i][head] + ',';
            }
        }
        str += line + '\r\n';
        console.log(str)
    }
    return str;
  }

  private convert_to_csv_header(header_data) {
    console.log(header_data)
    let str = '';
    for (let i = 0; i < header_data.length; i++) {
        let line = '';
        line += header_data[i];
        str += line + '\r\n';
    }
    return str;
  }

  get uuid() : string {
    return 'xxxxxxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  set_dynamic_height_width(class_name = '.dynamic_image'){
    var tag = document.querySelector(class_name);
    if(tag){
      this.width = tag.clientWidth;
      this.height = tag.clientHeight;
    }
  }

  clear_height_width(){
    this.height = this.width = undefined;
  }

  get_image(image_url) : string{
    return (this.height && this.width)  ? CONFIG.apiUrl+'/resize_image?width='+this.width+'&height='+this.height+'&format=jpg&image='+CONFIG.imageUrl+image_url : null;
  }

  onError(errorMessage,message = null): void {
    if(message !== null){
      this.notifications.create(
        message,message,NotificationType.Error,
        this.toastConfig
      );
    }else{
      errorMessage = string_json.default['error-code'][errorMessage] ? this.translate.instant('error-code.' + errorMessage) : errorMessage;
      if (errorMessage.length > 4){
        if(errorMessage){
          this.notifications.create(
            this.translate.instant('alert.error'),errorMessage,NotificationType.Error,
            this.toastConfig
          );
        }
      }
    }
  }

  onSuccess(successCode): void {
    if (string_json.default['success-code'][successCode]) {
      this.notifications.create(
        this.translate.instant('alert.success'),
        this.translate.instant('success-code.' + successCode),
        NotificationType.Success,
        this.toastConfig
      );
    }
  }

  getWeekDay(date){
    date = new Date(date);
    var first = date.getDate() - (date.getDay() - 1);
    var start_date:any = new Date(date.setDate(first))
    var last = first + 6;
    var end_date:any = new Date(date.setDate(last))

    if(first <= 0){
      end_date = new Date(date.setDate(last))
      end_date = new Date(new Date(end_date).setMonth(new Date(end_date).getMonth() + 1))
    }else{
      end_date = new Date(date.setDate(last))
    }

    start_date = new Date(new Date(start_date).setHours(0,0,0,0)).toUTCString()
    end_date = new Date(new Date(end_date).setHours(0,0,0,0)).toUTCString()

    return [start_date,end_date];
  }

  // onPrimary(): void{
  //   console.log(this.notifications)
  //   this.notifications.create(this.translate.instant('alert.primary'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Bare, { theClass: 'primary', timeOut: 3000, showProgressBar: true });
  // }

  // onSecondary(): void {
  //   this.notifications.create(this.translate.instant('alert.secondary'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Bare, { theClass: 'secondary', timeOut: 3000, showProgressBar: true });
  // }

  // onInfo(): void  {
  //   this.notifications.create(this.translate.instant('alert.info'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Info, { timeOut: 3000, showProgressBar: true });
  // }

  // onWarning(): void  {
  //   this.notifications.create(this.translate.instant('alert.warning'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Warn, { timeOut: 3000, showProgressBar: true });
  // }

  // onError(msg='Something Went Wrong',msgCode=2003): void  {
  //   this.notifications.create(msgCode,
  //     this.translate.instant(msg),
  //     NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  // }

  // onSuccess(): void  {
  //   this.notifications.create(this.translate.instant('alert.success'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  // }

}
