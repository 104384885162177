// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl : 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  dashboardUrl: 'http://13.235.27.173',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: false,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: false,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: "AIzaSyCLRAJRyv5421PbLgolNmL8BueMMOTRJU0",
    authDomain: "store-157305.firebaseapp.com",
    databaseURL: "https://store-157305.firebaseio.com",
    projectId: "store-157305",
    storageBucket: "store-157305.appspot.com",
    messagingSenderId: "1062107977876",
    appId: "1:1062107977876:web:ecaa423e96aa78dd36fc81"
  }
};
