import { Component, OnInit, OnDestroy, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { SocketService } from "src/app/shared/socket.service";
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { Helper } from 'src/app/shared/helper';
import { UserWebsiteService } from 'src/app/services/user-website.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-topnav',
  styleUrls:['./topnav.component.scss'],
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = '';
  bank_detail = '';
  languages: Language[];
  isSingleLang;
  isFullScreen = false;
  is_payment_fail = false;
  isDarkModeActive = false;
  no_of_days_remaining = 0;
  searchKey = '';
  store: any;
  IMAGE_URL: any;
  userWebsiteSubscriber: Subscription
  DEFAULT_IMAGE;
  profileSubscription:Subscription;
  modalRef1: BsModalRef;

  checklist = [];

  @ViewChild('template1', { static: true }) template1: TemplateRef<any>;

  constructor(private modalService: BsModalService,private sidebarService: SidebarService,
    private _helper:Helper,
    private socket: SocketService,
    private authService: AuthService,
    private profilerService:ProfileService,
    private router: Router,

    private langService: LangService,
    private _userWebsiteService: UserWebsiteService) {

    this.IMAGE_URL = _helper.IMAGE_URL
    this.DEFAULT_IMAGE = this._helper.DEFAULT_IMAGE_PATH.USER;

    this.languages = this.langService.supportedLanguages;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    var device_token = localStorage.getItem('device_token');
    if (device_token == "" || device_token == null || device_token == undefined) {
      let uuid = this._helper.uuid;
      localStorage.setItem('device_token', uuid);
    }
    // this.socket.listen('push'+device_token).subscribe( (res) =>{
      // var res_data = JSON.parse(JSON.stringify(res));
      /*if(this.helper.PUSH_CODE[res_data.messageCode]){
          this.helper.toastr.info(this.helper.PUSH_CODE[res_data.messageCode].en);
          if(res_data.messageCode != 2091 && res_data.messageCode != 2006 && res_data.messageCode != 2007){
              this.helper.OrderChange.next(res_data);
          }
      }else{
          this.helper.toastr.info(res_data.messageCode);
      }
      if(res_data.messageCode == 2091){
          var user = JSON.parse(localStorage.getItem('user'));
          this.helper.router_id.is_user_login = false;
          //this.socket.disconnetRoom(user._id);
          localStorage.removeItem('user');
          setTimeout(() => {
            this.helper.get_cart();
          },200);

          this.helper.router.navigate(['']);
      }*/
    // });
  }

  openCheckList(){
      let config = {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-right'
      };
      this.checklist = [];
      this.modalRef1 = this.modalService.show(this.template1 , config);
  }

  onConfirm(){
    this.modalRef1.hide();
    this.profilerService.store_apply_go_live({
      store_id: this.store._id,
    }).then(data=>{

    });
  }
 



  update_url(url) {
    let index = this.checklist.findIndex((x) => x == url);
    if (index == -1) {
      this.checklist.push(url)
    }else{
      this.checklist.splice(index, 1)

    }
    console.log(this.checklist)
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
  }

  async ngOnInit(): Promise<void> {
    var user = this.authService.getStore()
    let store= JSON.parse(localStorage.getItem('storeData'));
    store.is_business = user.is_business;
    store.is_applied_go_live = user.is_applied_go_live;
    store.is_approved = user.is_approved;
    store.is_go_live = user.is_go_live;
    localStorage.setItem('storeData', JSON.stringify(store));
    this.displayName = user.displayName;
    this.profilerService.fetch().then(storeData=>{
         this.bank_detail = storeData.bank_detail;
    })


    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );

    

    this.profileSubscription = this.profilerService._profileObservable.subscribe((data)=>{
      if(data){
          this.profilerService.fetch().then(storeData=>{
            this.store = storeData.store_detail;

            let store= JSON.parse(localStorage.getItem('storeData'));
            store.is_business = this.store.is_business;
            store.is_applied_go_live = this.store.is_applied_go_live;
            store.is_approved = this.store.is_approved;
            store.is_go_live = this.store.is_go_live;
            localStorage.setItem('storeData', JSON.stringify(store));
          })
      }
    });

    this.userWebsiteSubscriber = this._userWebsiteService._paymentGatewayObservable.subscribe(() => {
      this.store = JSON.parse(localStorage.getItem('storeData'))
    })

    this.socket.listener("update_store"+this.store._id).subscribe((res: any) =>{
     if(res){
       let store= JSON.parse(localStorage.getItem('storeData'));
        store.is_business = res.is_business;
        store.is_applied_go_live = res.is_applied_go_live;
        store.is_approved = res.is_approved;
        store.is_go_live = res.is_go_live;
        localStorage.setItem('storeData', JSON.stringify(store));
        this.store = store;
     }
    });

  }

  

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if(this.profileSubscription){
      this.profileSubscription.unsubscribe();
    }
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    this.authService.signOut()
  }

  searchKeyUp(event: KeyboardEvent): void {
    /*if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }*/
    this.router.navigate(['app/settings/payment']);
  }

  businessChange(is_business): void {
    this.profilerService.update({is_business:is_business})
  }
  searchAreaClick(event): void {
    event.stopPropagation();
  }

  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }
}
