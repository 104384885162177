import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


@Injectable({ providedIn: 'root' })
export class CommonService {

  private _image_settings;
  private _settings;
  private _tablebookingChanges = new BehaviorSubject<any>(null);
  tablebooking = this._tablebookingChanges.asObservable()
  constructor(private helper: Helper) {}
  
  get image_settings(){
    return this._image_settings;
  }


  get settings(){
    return this._settings;
  }


  store_image_settings():Promise<boolean> {
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_IMAGE_SETTING, {}).then((res_data)=>{
        if(res_data.success){this._image_settings  = res_data.image_setting}
        resolve(true)
      })
    })
  }

  getCountryList(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_COUNTRY_LIST, {})
  }

  emailVerification(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.EMAIL_VARIFICATION, json)
  }

  envApi(json) {
    return this.helper.http_post_method_requester('/api/store/easySignEnvelope', json)
  }

  getCityList(country_id){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_CITY_LIST, {country_id})
  }

  deleteAPI(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.DELETE_API, json)
  }

  getDeliveryList(city_id){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_DELIVERY_LIST_FOR_CITY, {city_id})
  }

  verify_captcha(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.VARIFY_CAPTCHA, json)
  }

  store_setting_details(){
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_SETTING_DETAIL, {}).then((res_data)=>{
        this._settings = res_data.setting;
        resolve(true);
      })
    })
  }

  otp_verification(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.OTP_VERIFICATION, json)
  }

  store_verification(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.STORE_GENERATE_OTP, json)
  }

  // resend_otp_verification(json){
  //   return this.helper.http_post_method_requester(this.helper.POST_METHOD.RESEND_OTP_VERIFICATION, json)
  // }

  addTable(json){
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_TABLE_INFO, json).then((res_data)=>{
        // this._settings = res_data.setting;
        this._tablebookingChanges.next({});
        resolve(true);
      })
    })
    // return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_TABLE_INFO, json)
  }

  updateTable(json){
    // this._tablebookingChanges.next({});
    // return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_TABLE_INFO, json)
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_TABLE_INFO, json).then((res_data)=>{
        // this._settings = res_data.setting;
        this._tablebookingChanges.next({});
        resolve(true);
      })
    })
  }

  getTableList(json){
    // return this.helper.http_post_method_requester(this.helper.POST_METHOD.TABLE_INFO_LIST, json)
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.TABLE_INFO_LIST, json).then((res_data)=>{
        // this._settings = res_data.setting;
        // this._tablebookingChanges.next({});
        resolve(true);
      })
    })
  }
}
