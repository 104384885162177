export var ORDER_STATE = {
    WAITING_FOR_ACCEPT_STORE: 1,
    CANCELED_BY_USER: 101,
    STORE_ACCEPTED: 3,
    STORE_REJECTED: 103,
    STORE_CANCELLED: 104,
    STORE_CANCELLED_REQUEST: 105,
    STORE_PREPARING_ORDER: 5,
    OREDER_READY: 7,
    WAITING_FOR_DELIVERY_MAN: 9,
    NO_DELIVERY_MAN_FOUND: 109,
    DELIVERY_MAN_ACCEPTED: 11,
    DELIVERY_MAN_REJECTED: 111,
    DELIVERY_MAN_CANCELLED: 112,
    DELIVERY_MAN_COMING: 13,
    DELIVERY_MAN_ARRIVED: 15,
    DELIVERY_MAN_PICKED_ORDER: 17,
    DELIVERY_MAN_STARTED_DELIVERY: 19,
    DELIVERY_MAN_ARRIVED_AT_DESTINATION: 21,
    DELIVERY_MAN_COMPLETE_DELIVERY: 23,
    ORDER_COMPLETED: 25,
};

export var DEFAULT_IMAGE_PATH = {
    PROMO_CODE: '/assets/img/default_images/promo_code.png',
    USER: '/assets/img/default_images/user.png',
    CATEGORY: '/assets/img/default_images/category.png',
    DRIVER: '/assets/img/default_images/driver.png'
}


export var PER_PAGE_LIST = [5,10,15]

export var STRIPE_CURRENCY = [
"usd",
"aed",
"afn",
"all",
"amd",
"ang",
"aoa",
"ars",
"aud",
"awg",
"azn",
"bam",
"bbd",
"bdt",
"bgn",
"bif",
"bmd",
"bnd",
"bob",
"brl",
"bsd",
"bwp",
"bzd",
"cad",
"cdf",
"chf",
"clp",
"cny",
"cop",
"crc",
"cve",
"czk",
"djf",
"dkk",
"dop",
"dzd",
"egp",
"etb",
"eur",
"fjd",
"fkp",
"gbp",
"gel",
"gip",
"gmd",
"gnf",
"gtq",
"gyd",
"hkd",
"hnl",
"hrk",
"htg",
"huf",
"idr",
"ils",
"inr",
"isk",
"jmd",
"jpy",
"kes",
"kgs",
"khr",
"kmf",
"krw",
"kyd",
"kzt",
"lak",
"lbp",
"lkr",
"lrd",
"lsl",
"mad",
"mdl",
"mga",
"mkd",
"mmk",
"mnt",
"mop",
"mro",
"mur",
"mvr",
"mwk",
"mxn",
"myr",
"mzn",
"nad",
"ngn",
"nio",
"nok",
"npr",
"nzd",
"pab",
"pen",
"pgk",
"php",
"pkr",
"pln",
"pyg",
"qar",
"ron",
"rsd",
"rub",
"rwf",
"sar",
"sbd",
"scr",
"sek",
"sgd",
"shp",
"sll",
"sos",
"srd",
"std",
"szl",
"thb",
"tjs",
"top",
"try",
"ttd",
"twd",
"tzs",
"uah",
"ugx",
"uyu",
"uzs",
"vnd",
"vuv",
"wst",
"xaf",
"xcd",
"xof",
"xpf",
"yer",
"zar",
"zmw"
]
export var COUNTRIES = [
   {
      "name":"Ascension Island",
      "flag":"AC",
      "code":"AC",
      "code_3":"",
      "calling_codes":[
         "+247"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Atlantic/St_Helena"
      ]
   },
   {
      "name":"Andorra",
      "flag":"AD",
      "code":"AD",
      "code_3":"AND",
      "calling_codes":[
         "+376"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Andorra"
      ]
   },
   {
      "name":"United Arab Emirates",
      "flag":"AE",
      "code":"AE",
      "code_3":"ARE",
      "calling_codes":[
         "+971"
      ],
      "currencies":[
         "AED"
      ],
      "timezones":[
         "Asia/Dubai"
      ]
   },
   {
      "name":"Afghanistan",
      "flag":"AF",
      "code":"AF",
      "code_3":"AFG",
      "calling_codes":[
         "+93"
      ],
      "currencies":[
         "AFN"
      ],
      "timezones":[
         "Asia/Kabul"
      ]
   },
   {
      "name":"Antigua And Barbuda",
      "flag":"AG",
      "code":"AG",
      "code_3":"ATG",
      "calling_codes":[
         "+1 268"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "America/Antigua"
      ]
   },
   {
      "name":"Anguilla",
      "flag":"AI",
      "code":"AI",
      "code_3":"AIA",
      "calling_codes":[
         "+1 264"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "America/Anguilla"
      ]
   },
   {
      "name":"Albania",
      "flag":"AL",
      "code":"AL",
      "code_3":"ALB",
      "calling_codes":[
         "+355"
      ],
      "currencies":[
         "ALL"
      ],
      "timezones":[
         "Europe/Tirane"
      ]
   },
   {
      "name":"Armenia",
      "flag":"AM",
      "code":"AM",
      "code_3":"ARM",
      "calling_codes":[
         "+374"
      ],
      "currencies":[
         "AMD"
      ],
      "timezones":[
         "Asia/Yerevan"
      ]
   },
   {
      "name":"Angola",
      "flag":"AO",
      "code":"AO",
      "code_3":"AGO",
      "calling_codes":[
         "+244"
      ],
      "currencies":[
         "AOA"
      ],
      "timezones":[
         "Africa/Luanda"
      ]
   },
   {
      "name":"Antarctica",
      "flag":"AQ",
      "code":"AQ",
      "code_3":"ATA",
      "calling_codes":[
         "+672"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "Antarctica/McMurdo",
         "Antarctica/Casey",
         "Antarctica/Davis",
         "Antarctica/DumontDUrville",
         "Antarctica/Mawson",
         "Antarctica/Palmer",
         "Antarctica/Rothera",
         "Antarctica/Syowa",
         "Antarctica/Troll",
         "Antarctica/Vostok"
      ]
   },
   {
      "name":"Argentina",
      "flag":"AR",
      "code":"AR",
      "code_3":"ARG",
      "calling_codes":[
         "+54"
      ],
      "currencies":[
         "ARS"
      ],
      "timezones":[
         "America/Argentina/Buenos_Aires",
         "America/Argentina/Cordoba",
         "America/Argentina/Salta",
         "America/Argentina/Jujuy",
         "America/Argentina/Tucuman",
         "America/Argentina/Catamarca",
         "America/Argentina/La_Rioja",
         "America/Argentina/San_Juan",
         "America/Argentina/Mendoza",
         "America/Argentina/San_Luis",
         "America/Argentina/Rio_Gallegos",
         "America/Argentina/Ushuaia"
      ]
   },
   {
      "name":"American Samoa",
      "flag":"AS",
      "code":"AS",
      "code_3":"ASM",
      "calling_codes":[
         "+1 684"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Pacific/Pago_Pago"
      ]
   },
   {
      "name":"Austria",
      "flag":"AT",
      "code":"AT",
      "code_3":"AUT",
      "calling_codes":[
         "+43"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Vienna"
      ]
   },
   {
      "name":"Australia",
      "flag":"AU",
      "code":"AU",
      "code_3":"AUS",
      "calling_codes":[
         "+61"
      ],
      "currencies":[
         "AUD"
      ],
      "timezones":[
         "Australia/Lord_Howe",
         "Antarctica/Macquarie",
         "Australia/Hobart",
         "Australia/Currie",
         "Australia/Melbourne",
         "Australia/Sydney",
         "Australia/Broken_Hill",
         "Australia/Brisbane",
         "Australia/Lindeman",
         "Australia/Adelaide",
         "Australia/Darwin",
         "Australia/Perth",
         "Australia/Eucla"
      ]
   },
   {
      "name":"Aruba",
      "flag":"AW",
      "code":"AW",
      "code_3":"ABW",
      "calling_codes":[
         "+297"
      ],
      "currencies":[
         "AWG"
      ],
      "timezones":[
         "America/Aruba"
      ]
   },
   {
      "name":"Åland Islands",
      "flag":"AX",
      "code":"AX",
      "code_3":"ALA",
      "calling_codes":[
         "+358"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Mariehamn"
      ]
   },
   {
      "name":"Azerbaijan",
      "flag":"AZ",
      "code":"AZ",
      "code_3":"AZE",
      "calling_codes":[
         "+994"
      ],
      "currencies":[
         "AZN"
      ],
      "timezones":[
         "Asia/Baku"
      ]
   },
   {
      "name":"Bosnia & Herzegovina",
      "flag":"BA",
      "code":"BA",
      "code_3":"BIH",
      "calling_codes":[
         "+387"
      ],
      "currencies":[
         "BAM"
      ],
      "timezones":[
         "Europe/Sarajevo"
      ]
   },
   {
      "name":"Barbados",
      "flag":"BB",
      "code":"BB",
      "code_3":"BRB",
      "calling_codes":[
         "+1 246"
      ],
      "currencies":[
         "BBD"
      ],
      "timezones":[
         "America/Barbados"
      ]
   },
   {
      "name":"Bangladesh",
      "flag":"BD",
      "code":"BD",
      "code_3":"BGD",
      "calling_codes":[
         "+880"
      ],
      "currencies":[
         "BDT"
      ],
      "timezones":[
         "Asia/Dhaka"
      ]
   },
   {
      "name":"Belgium",
      "flag":"BE",
      "code":"BE",
      "code_3":"BEL",
      "calling_codes":[
         "+32"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Brussels"
      ]
   },
   {
      "name":"Burkina Faso",
      "flag":"BF",
      "code":"BF",
      "code_3":"BFA",
      "calling_codes":[
         "+226"
      ],
      "currencies":[
         "XOF"
      ],
      "timezones":[
         "Africa/Ouagadougou"
      ]
   },
   {
      "name":"Bulgaria",
      "flag":"BG",
      "code":"BG",
      "code_3":"BGR",
      "calling_codes":[
         "+359"
      ],
      "currencies":[
         "BGN"
      ],
      "timezones":[
         "Europe/Sofia"
      ]
   },
   {
      "name":"Bahrain",
      "flag":"BH",
      "code":"BH",
      "code_3":"BHR",
      "calling_codes":[
         "+973"
      ],
      "currencies":[
         "BHD"
      ],
      "timezones":[
         "Asia/Bahrain"
      ]
   },
   {
      "name":"Burundi",
      "flag":"BI",
      "code":"BI",
      "code_3":"BDI",
      "calling_codes":[
         "+257"
      ],
      "currencies":[
         "BIF"
      ],
      "timezones":[
         "Africa/Bujumbura"
      ]
   },
   {
      "name":"Benin",
      "flag":"BJ",
      "code":"BJ",
      "code_3":"BEN",
      "calling_codes":[
         "+229"
      ],
      "currencies":[
         "XOF"
      ],
      "timezones":[
         "Africa/Porto-Novo"
      ]
   },
   {
      "name":"Saint Barthélemy",
      "flag":"BL",
      "code":"BL",
      "code_3":"BLM",
      "calling_codes":[
         "+590"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "America/St_Barthelemy"
      ]
   },
   {
      "name":"Bermuda",
      "flag":"BM",
      "code":"BM",
      "code_3":"BMU",
      "calling_codes":[
         "+1 441"
      ],
      "currencies":[
         "BMD"
      ],
      "timezones":[
         "Atlantic/Bermuda"
      ]
   },
   {
      "name":"Brunei Darussalam",
      "flag":"BN",
      "code":"BN",
      "code_3":"BRN",
      "calling_codes":[
         "+673"
      ],
      "currencies":[
         "BND"
      ],
      "timezones":[
         "Asia/Brunei"
      ]
   },
   {
      "name":"Bolivia, Plurinational State Of",
      "flag":"BO",
      "code":"BO",
      "code_3":"BOL",
      "calling_codes":[
         "+591"
      ],
      "currencies":[
         "BOB"
      ],
      "timezones":[
         "America/La_Paz"
      ]
   },
   {
      "name":"Bonaire, Saint Eustatius And Saba",
      "flag":"BQ",
      "code":"BQ",
      "code_3":"BES",
      "calling_codes":[
         "+599"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "America/Kralendijk"
      ]
   },
   {
      "name":"Brazil",
      "flag":"BR",
      "code":"BR",
      "code_3":"BRA",
      "calling_codes":[
         "+55"
      ],
      "currencies":[
         "BRL"
      ],
      "timezones":[
         "America/Noronha",
         "America/Belem",
         "America/Fortaleza",
         "America/Recife",
         "America/Araguaina",
         "America/Maceio",
         "America/Bahia",
         "America/Sao_Paulo",
         "America/Campo_Grande",
         "America/Cuiaba",
         "America/Santarem",
         "America/Porto_Velho",
         "America/Boa_Vista",
         "America/Manaus",
         "America/Eirunepe",
         "America/Rio_Branco"
      ]
   },
   {
      "name":"Bahamas",
      "flag":"BS",
      "code":"BS",
      "code_3":"BHS",
      "calling_codes":[
         "+1 242"
      ],
      "currencies":[
         "BSD"
      ],
      "timezones":[
         "America/Nassau"
      ]
   },
   {
      "name":"Bhutan",
      "flag":"BT",
      "code":"BT",
      "code_3":"BTN",
      "calling_codes":[
         "+975"
      ],
      "currencies":[
         "BTN"
      ],
      "timezones":[
         "Asia/Thimphu"
      ]
   },
   {
      "name":"Botswana",
      "flag":"BW",
      "code":"BW",
      "code_3":"BWA",
      "calling_codes":[
         "+267"
      ],
      "currencies":[
         "BWP"
      ],
      "timezones":[
         "Africa/Gaborone"
      ]
   },
   {
      "name":"Belarus",
      "flag":"BY",
      "code":"BY",
      "code_3":"BLR",
      "calling_codes":[
         "+375"
      ],
      "currencies":[
         "BYR"
      ],
      "timezones":[
         "Europe/Minsk"
      ]
   },
   {
      "name":"Belize",
      "flag":"BZ",
      "code":"BZ",
      "code_3":"BLZ",
      "calling_codes":[
         "+501"
      ],
      "currencies":[
         "BZD"
      ],
      "timezones":[
         "America/Belize"
      ]
   },
   {
      "name":"Canada",
      "flag":"CA",
      "code":"CA",
      "code_3":"CAN",
      "calling_codes":[
         "+1"
      ],
      "currencies":[
         "CAD"
      ],
      "timezones":[
         "America/St_Johns",
         "America/Halifax",
         "America/Glace_Bay",
         "America/Moncton",
         "America/Goose_Bay",
         "America/Blanc-Sablon",
         "America/Toronto",
         "America/Nipigon",
         "America/Thunder_Bay",
         "America/Iqaluit",
         "America/Pangnirtung",
         "America/Atikokan",
         "America/Winnipeg",
         "America/Rainy_River",
         "America/Resolute",
         "America/Rankin_Inlet",
         "America/Regina",
         "America/Swift_Current",
         "America/Edmonton",
         "America/Cambridge_Bay",
         "America/Yellowknife",
         "America/Inuvik",
         "America/Creston",
         "America/Dawson_Creek",
         "America/Fort_Nelson",
         "America/Vancouver",
         "America/Whitehorse",
         "America/Dawson"
      ]
   },
   {
      "name":"Cocos (Keeling) Islands",
      "flag":"CC",
      "code":"CC",
      "code_3":"CCK",
      "calling_codes":[
         "+61"
      ],
      "currencies":[
         "AUD"
      ],
      "timezones":[
         "Indian/Cocos"
      ]
   },
   {
      "name":"Democratic Republic Of Congo",
      "flag":"CD",
      "code":"CD",
      "code_3":"COD",
      "calling_codes":[
         "+243"
      ],
      "currencies":[
         "CDF"
      ],
      "timezones":[
         "Africa/Kinshasa",
         "Africa/Lubumbashi"
      ]
   },
   {
      "name":"Central African Republic",
      "flag":"CF",
      "code":"CF",
      "code_3":"CAF",
      "calling_codes":[
         "+236"
      ],
      "currencies":[
         "XAF"
      ],
      "timezones":[
         "Africa/Bangui"
      ]
   },
   {
      "name":"Republic Of Congo",
      "flag":"CG",
      "code":"CG",
      "code_3":"COG",
      "calling_codes":[
         "+242"
      ],
      "currencies":[
         "XAF"
      ],
      "timezones":[
         "Africa/Brazzaville"
      ]
   },
   {
      "name":"Switzerland",
      "flag":"CH",
      "code":"CH",
      "code_3":"CHE",
      "calling_codes":[
         "+41"
      ],
      "currencies":[
         "CHF"
      ],
      "timezones":[
         "Europe/Zurich"
      ]
   },
   {
      "name":"Côte d'Ivoire",
      "flag":"CI",
      "code":"CI",
      "code_3":"CIV",
      "calling_codes":[
         "+225"
      ],
      "currencies":[
         "XOF"
      ],
      "timezones":[
         "Africa/Abidjan"
      ]
   },
   {
      "name":"Cook Islands",
      "flag":"CK",
      "code":"CK",
      "code_3":"COK",
      "calling_codes":[
         "+682"
      ],
      "currencies":[
         "NZD"
      ],
      "timezones":[
         "Pacific/Rarotonga"
      ]
   },
   {
      "name":"Chile",
      "flag":"CL",
      "code":"CL",
      "code_3":"CHL",
      "calling_codes":[
         "+56"
      ],
      "currencies":[
         "CLP"
      ],
      "timezones":[
         "America/Santiago",
         "Pacific/Easter"
      ]
   },
   {
      "name":"Cameroon",
      "flag":"CM",
      "code":"CM",
      "code_3":"CMR",
      "calling_codes":[
         "+237"
      ],
      "currencies":[
         "XAF"
      ],
      "timezones":[
         "Africa/Douala"
      ]
   },
   {
      "name":"China",
      "flag":"CN",
      "code":"CN",
      "code_3":"CHN",
      "calling_codes":[
         "+86"
      ],
      "currencies":[
         "CNY"
      ],
      "timezones":[
         "Asia/Shanghai",
         "Asia/Urumqi"
      ]
   },
   {
      "name":"Colombia",
      "flag":"CO",
      "code":"CO",
      "code_3":"COL",
      "calling_codes":[
         "+57"
      ],
      "currencies":[
         "COP"
      ],
      "timezones":[
         "America/Bogota"
      ]
   },
   {
      "name":"Costa Rica",
      "flag":"CR",
      "code":"CR",
      "code_3":"CRI",
      "calling_codes":[
         "+506"
      ],
      "currencies":[
         "CRC"
      ],
      "timezones":[
         "America/Costa_Rica"
      ]
   },
   {
      "name":"Cuba",
      "flag":"CU",
      "code":"CU",
      "code_3":"CUB",
      "calling_codes":[
         "+53"
      ],
      "currencies":[
         "CUP"
      ],
      "timezones":[
         "America/Havana"
      ]
   },
   {
      "name":"Cabo Verde",
      "flag":"CV",
      "code":"CV",
      "code_3":"CPV",
      "calling_codes":[
         "+238"
      ],
      "currencies":[
         "CVE"
      ],
      "timezones":[
         "Atlantic/Cape_Verde"
      ]
   },
   {
      "name":"Curacao",
      "flag":"CW",
      "code":"CW",
      "code_3":"CUW",
      "calling_codes":[
         "+599"
      ],
      "currencies":[
         "ANG"
      ],
      "timezones":[
         "America/Curacao"
      ]
   },
   {
      "name":"Christmas Island",
      "flag":"CX",
      "code":"CX",
      "code_3":"CXR",
      "calling_codes":[
         "+61"
      ],
      "currencies":[
         "AUD"
      ],
      "timezones":[
         "Indian/Christmas"
      ]
   },
   {
      "name":"Cyprus",
      "flag":"CY",
      "code":"CY",
      "code_3":"CYP",
      "calling_codes":[
         "+357"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Asia/Nicosia"
      ]
   },
   {
      "name":"Czech Republic",
      "flag":"CZ",
      "code":"CZ",
      "code_3":"CZE",
      "calling_codes":[
         "+420"
      ],
      "currencies":[
         "CZK"
      ],
      "timezones":[
         "Europe/Prague"
      ]
   },
   {
      "name":"Germany",
      "flag":"DE",
      "code":"DE",
      "code_3":"DEU",
      "calling_codes":[
         "+49"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Berlin",
         "Europe/Busingen"
      ]
   },
   {
      "name":"Djibouti",
      "flag":"DJ",
      "code":"DJ",
      "code_3":"DJI",
      "calling_codes":[
         "+253"
      ],
      "currencies":[
         "DJF"
      ],
      "timezones":[
         "Africa/Djibouti"
      ]
   },
   {
      "name":"Denmark",
      "flag":"DK",
      "code":"DK",
      "code_3":"DNK",
      "calling_codes":[
         "+45"
      ],
      "currencies":[
         "DKK"
      ],
      "timezones":[
         "Europe/Copenhagen"
      ]
   },
   {
      "name":"Dominica",
      "flag":"DM",
      "code":"DM",
      "code_3":"DMA",
      "calling_codes":[
         "+1 767"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "America/Dominica"
      ]
   },
   {
      "name":"Dominican Republic",
      "flag":"DO",
      "code":"DO",
      "code_3":"DOM",
      "calling_codes":[
         "+1 809",
         "+1 829",
         "+1 849"
      ],
      "currencies":[
         "DOP"
      ],
      "timezones":[
         "America/Santo_Domingo"
      ]
   },
   {
      "name":"Algeria",
      "flag":"DZ",
      "code":"DZ",
      "code_3":"DZA",
      "calling_codes":[
         "+213"
      ],
      "currencies":[
         "DZD"
      ],
      "timezones":[
         "Africa/Algiers"
      ]
   },
   {
      "name":"Ecuador",
      "flag":"EC",
      "code":"EC",
      "code_3":"ECU",
      "calling_codes":[
         "+593"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "America/Guayaquil",
         "Pacific/Galapagos"
      ]
   },
   {
      "name":"Estonia",
      "flag":"EE",
      "code":"EE",
      "code_3":"EST",
      "calling_codes":[
         "+372"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Tallinn"
      ]
   },
   {
      "name":"Egypt",
      "flag":"EG",
      "code":"EG",
      "code_3":"EGY",
      "calling_codes":[
         "+20"
      ],
      "currencies":[
         "EGP"
      ],
      "timezones":[
         "Africa/Cairo"
      ]
   },
   {
      "name":"Western Sahara",
      "flag":"EH",
      "code":"EH",
      "code_3":"ESH",
      "calling_codes":[
         "+212"
      ],
      "currencies":[
         "MAD"
      ],
      "timezones":[
         "Africa/El_Aaiun"
      ]
   },
   {
      "name":"Eritrea",
      "flag":"ER",
      "code":"ER",
      "code_3":"ERI",
      "calling_codes":[
         "+291"
      ],
      "currencies":[
         "ERN"
      ],
      "timezones":[
         "Africa/Asmara"
      ]
   },
   {
      "name":"Spain",
      "flag":"ES",
      "code":"ES",
      "code_3":"ESP",
      "calling_codes":[
         "+34"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Madrid",
         "Africa/Ceuta",
         "Atlantic/Canary"
      ]
   },
   {
      "name":"Ethiopia",
      "flag":"ET",
      "code":"ET",
      "code_3":"ETH",
      "calling_codes":[
         "+251"
      ],
      "currencies":[
         "ETB"
      ],
      "timezones":[
         "Africa/Addis_Ababa"
      ]
   },
   {
      "name":"Finland",
      "flag":"FI",
      "code":"FI",
      "code_3":"FIN",
      "calling_codes":[
         "+358"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Helsinki"
      ]
   },
   {
      "name":"Fiji",
      "flag":"FJ",
      "code":"FJ",
      "code_3":"FJI",
      "calling_codes":[
         "+679"
      ],
      "currencies":[
         "FJD"
      ],
      "timezones":[
         "Pacific/Fiji"
      ]
   },
   {
      "name":"Falkland Islands",
      "flag":"FK",
      "code":"FK",
      "code_3":"FLK",
      "calling_codes":[
         "+500"
      ],
      "currencies":[
         "FKP"
      ],
      "timezones":[
         "Atlantic/Stanley"
      ]
   },
   {
      "name":"Micronesia, Federated States Of",
      "flag":"FM",
      "code":"FM",
      "code_3":"FSM",
      "calling_codes":[
         "+691"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Pacific/Chuuk",
         "Pacific/Pohnpei",
         "Pacific/Kosrae"
      ]
   },
   {
      "name":"Faroe Islands",
      "flag":"FO",
      "code":"FO",
      "code_3":"FRO",
      "calling_codes":[
         "+298"
      ],
      "currencies":[
         "DKK"
      ],
      "timezones":[
         "Atlantic/Faroe"
      ]
   },
   {
      "name":"France",
      "flag":"FR",
      "code":"FR",
      "code_3":"FRA",
      "calling_codes":[
         "+33"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Paris"
      ]
   },
   {
      "name":"Gabon",
      "flag":"GA",
      "code":"GA",
      "code_3":"GAB",
      "calling_codes":[
         "+241"
      ],
      "currencies":[
         "XAF"
      ],
      "timezones":[
         "Africa/Libreville"
      ]
   },
   {
      "name":"United Kingdom",
      "flag":"GB",
      "code":"GB",
      "code_3":"GBR",
      "calling_codes":[
         "+44"
      ],
      "currencies":[
         "GBP"
      ],
      "timezones":[
         "Europe/London"
      ]
   },
   {
      "name":"Grenada",
      "flag":"GD",
      "code":"GD",
      "code_3":"GRD",
      "calling_codes":[
         "+473"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "America/Grenada"
      ]
   },
   {
      "name":"Georgia",
      "flag":"GE",
      "code":"GE",
      "code_3":"GEO",
      "calling_codes":[
         "+995"
      ],
      "currencies":[
         "GEL"
      ],
      "timezones":[
         "Asia/Tbilisi"
      ]
   },
   {
      "name":"French Guiana",
      "flag":"GF",
      "code":"GF",
      "code_3":"GUF",
      "calling_codes":[
         "+594"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "America/Cayenne"
      ]
   },
   {
      "name":"Guernsey",
      "flag":"GG",
      "code":"GG",
      "code_3":"GGY",
      "calling_codes":[
         "+44"
      ],
      "currencies":[
         "GBP"
      ],
      "timezones":[
         "Europe/Guernsey"
      ]
   },
   {
      "name":"Ghana",
      "flag":"GH",
      "code":"GH",
      "code_3":"GHA",
      "calling_codes":[
         "+233"
      ],
      "currencies":[
         "GHS"
      ],
      "timezones":[
         "Africa/Accra"
      ]
   },
   {
      "name":"Gibraltar",
      "flag":"GI",
      "code":"GI",
      "code_3":"GIB",
      "calling_codes":[
         "+350"
      ],
      "currencies":[
         "GIP"
      ],
      "timezones":[
         "Europe/Gibraltar"
      ]
   },
   {
      "name":"Greenland",
      "flag":"GL",
      "code":"GL",
      "code_3":"GRL",
      "calling_codes":[
         "+299"
      ],
      "currencies":[
         "DKK"
      ],
      "timezones":[
         "America/Godthab",
         "America/Danmarkshavn",
         "America/Scoresbysund",
         "America/Thule"
      ]
   },
   {
      "name":"Gambia",
      "flag":"GM",
      "code":"GM",
      "code_3":"GMB",
      "calling_codes":[
         "+220"
      ],
      "currencies":[
         "GMD"
      ],
      "timezones":[
         "Africa/Banjul"
      ]
   },
   {
      "name":"Guinea",
      "flag":"GN",
      "code":"GN",
      "code_3":"GIN",
      "calling_codes":[
         "+224"
      ],
      "currencies":[
         "GNF"
      ],
      "timezones":[
         "Africa/Conakry"
      ]
   },
   {
      "name":"Guadeloupe",
      "flag":"GP",
      "code":"GP",
      "code_3":"GLP",
      "calling_codes":[
         "+590"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "America/Guadeloupe"
      ]
   },
   {
      "name":"Equatorial Guinea",
      "flag":"GQ",
      "code":"GQ",
      "code_3":"GNQ",
      "calling_codes":[
         "+240"
      ],
      "currencies":[
         "XAF"
      ],
      "timezones":[
         "Africa/Malabo"
      ]
   },
   {
      "name":"Greece",
      "flag":"GR",
      "code":"GR",
      "code_3":"GRC",
      "calling_codes":[
         "+30"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Athens"
      ]
   },
   {
      "name":"Guatemala",
      "flag":"GT",
      "code":"GT",
      "code_3":"GTM",
      "calling_codes":[
         "+502"
      ],
      "currencies":[
         "GTQ"
      ],
      "timezones":[
         "America/Guatemala"
      ]
   },
   {
      "name":"Guam",
      "flag":"GU",
      "code":"GU",
      "code_3":"GUM",
      "calling_codes":[
         "+1 671"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Pacific/Guam"
      ]
   },
   {
      "name":"Guinea-bissau",
      "flag":"GW",
      "code":"GW",
      "code_3":"GNB",
      "calling_codes":[
         "+245"
      ],
      "currencies":[
         "XOF"
      ],
      "timezones":[
         "Africa/Bissau"
      ]
   },
   {
      "name":"Guyana",
      "flag":"GY",
      "code":"GY",
      "code_3":"GUY",
      "calling_codes":[
         "+592"
      ],
      "currencies":[
         "GYD"
      ],
      "timezones":[
         "America/Guyana"
      ]
   },
   {
      "name":"Hong Kong",
      "flag":"HK",
      "code":"HK",
      "code_3":"HKG",
      "calling_codes":[
         "+852"
      ],
      "currencies":[
         "HKD"
      ],
      "timezones":[
         "Asia/Hong_Kong"
      ]
   },
   {
      "name":"Honduras",
      "flag":"HN",
      "code":"HN",
      "code_3":"HND",
      "calling_codes":[
         "+504"
      ],
      "currencies":[
         "HNL"
      ],
      "timezones":[
         "America/Tegucigalpa"
      ]
   },
   {
      "name":"Croatia",
      "flag":"HR",
      "code":"HR",
      "code_3":"HRV",
      "calling_codes":[
         "+385"
      ],
      "currencies":[
         "HRK"
      ],
      "timezones":[
         "Europe/Zagreb"
      ]
   },
   {
      "name":"Haiti",
      "flag":"HT",
      "code":"HT",
      "code_3":"HTI",
      "calling_codes":[
         "+509"
      ],
      "currencies":[
         "HTG"
      ],
      "timezones":[
         "America/Port-au-Prince"
      ]
   },
   {
      "name":"Hungary",
      "flag":"HU",
      "code":"HU",
      "code_3":"HUN",
      "calling_codes":[
         "+36"
      ],
      "currencies":[
         "HUF"
      ],
      "timezones":[
         "Europe/Budapest"
      ]
   },
   {
      "name":"Indonesia",
      "flag":"ID",
      "code":"ID",
      "code_3":"IDN",
      "calling_codes":[
         "+62"
      ],
      "currencies":[
         "IDR"
      ],
      "timezones":[
         "Asia/Jakarta",
         "Asia/Pontianak",
         "Asia/Makassar",
         "Asia/Jayapura"
      ]
   },
   {
      "name":"Ireland",
      "flag":"IE",
      "code":"IE",
      "code_3":"IRL",
      "calling_codes":[
         "+353"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Dublin"
      ]
   },
   {
      "name":"Israel",
      "flag":"IL",
      "code":"IL",
      "code_3":"ISR",
      "calling_codes":[
         "+972"
      ],
      "currencies":[
         "ILS"
      ],
      "timezones":[
         "Asia/Jerusalem"
      ]
   },
   {
      "name":"Isle Of Man",
      "flag":"IM",
      "code":"IM",
      "code_3":"IMN",
      "calling_codes":[
         "+44"
      ],
      "currencies":[
         "GBP"
      ],
      "timezones":[
         "Europe/Isle_of_Man"
      ]
   },
   {
      "name":"India",
      "flag":"IN",
      "code":"IN",
      "code_3":"IND",
      "calling_codes":[
         "+91"
      ],
      "currencies":[
         "INR"
      ],
      "timezones":[
         "Asia/Kolkata"
      ]
   },
   {
      "name":"British Indian Ocean Territory",
      "flag":"IO",
      "code":"IO",
      "code_3":"IOT",
      "calling_codes":[
         "+246"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Indian/Chagos"
      ]
   },
   {
      "name":"Iraq",
      "flag":"IQ",
      "code":"IQ",
      "code_3":"IRQ",
      "calling_codes":[
         "+964"
      ],
      "currencies":[
         "IQD"
      ],
      "timezones":[
         "Asia/Baghdad"
      ]
   },
   {
      "name":"Iran, Islamic Republic Of",
      "flag":"IR",
      "code":"IR",
      "code_3":"IRN",
      "calling_codes":[
         "+98"
      ],
      "currencies":[
         "IRR"
      ],
      "timezones":[
         "Asia/Tehran"
      ]
   },
   {
      "name":"Iceland",
      "flag":"IS",
      "code":"IS",
      "code_3":"ISL",
      "calling_codes":[
         "+354"
      ],
      "currencies":[
         "ISK"
      ],
      "timezones":[
         "Atlantic/Reykjavik"
      ]
   },
   {
      "name":"Italy",
      "flag":"IT",
      "code":"IT",
      "code_3":"ITA",
      "calling_codes":[
         "+39"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Rome"
      ]
   },
   {
      "name":"Jersey",
      "flag":"JE",
      "code":"JE",
      "code_3":"JEY",
      "calling_codes":[
         "+44"
      ],
      "currencies":[
         "GBP"
      ],
      "timezones":[
         "Europe/Jersey"
      ]
   },
   {
      "name":"Jamaica",
      "flag":"JM",
      "code":"JM",
      "code_3":"JAM",
      "calling_codes":[
         "+1 876"
      ],
      "currencies":[
         "JMD"
      ],
      "timezones":[
         "America/Jamaica"
      ]
   },
   {
      "name":"Jordan",
      "flag":"JO",
      "code":"JO",
      "code_3":"JOR",
      "calling_codes":[
         "+962"
      ],
      "currencies":[
         "JOD"
      ],
      "timezones":[
         "Asia/Amman"
      ]
   },
   {
      "name":"Japan",
      "flag":"JP",
      "code":"JP",
      "code_3":"JPN",
      "calling_codes":[
         "+81"
      ],
      "currencies":[
         "JPY"
      ],
      "timezones":[
         "Asia/Tokyo"
      ]
   },
   {
      "name":"Kenya",
      "flag":"KE",
      "code":"KE",
      "code_3":"KEN",
      "calling_codes":[
         "+254"
      ],
      "currencies":[
         "KES"
      ],
      "timezones":[
         "Africa/Nairobi"
      ]
   },
   {
      "name":"Kyrgyzstan",
      "flag":"KG",
      "code":"KG",
      "code_3":"KGZ",
      "calling_codes":[
         "+996"
      ],
      "currencies":[
         "KGS"
      ],
      "timezones":[
         "Asia/Bishkek"
      ]
   },
   {
      "name":"Cambodia",
      "flag":"KH",
      "code":"KH",
      "code_3":"KHM",
      "calling_codes":[
         "+855"
      ],
      "currencies":[
         "KHR"
      ],
      "timezones":[
         "Asia/Phnom_Penh"
      ]
   },
   {
      "name":"Kiribati",
      "flag":"KI",
      "code":"KI",
      "code_3":"KIR",
      "calling_codes":[
         "+686"
      ],
      "currencies":[
         "AUD"
      ],
      "timezones":[
         "Pacific/Tarawa",
         "Pacific/Enderbury",
         "Pacific/Kiritimati"
      ]
   },
   {
      "name":"Comoros",
      "flag":"KM",
      "code":"KM",
      "code_3":"COM",
      "calling_codes":[
         "+269"
      ],
      "currencies":[
         "KMF"
      ],
      "timezones":[
         "Indian/Comoro"
      ]
   },
   {
      "name":"Saint Kitts And Nevis",
      "flag":"KN",
      "code":"KN",
      "code_3":"KNA",
      "calling_codes":[
         "+1 869"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "America/St_Kitts"
      ]
   },
   {
      "name":"Korea, Democratic People's Republic Of",
      "flag":"KP",
      "code":"KP",
      "code_3":"PRK",
      "calling_codes":[
         "+850"
      ],
      "currencies":[
         "KPW"
      ],
      "timezones":[
         "Asia/Pyongyang"
      ]
   },
   {
      "name":"Korea, Republic Of",
      "flag":"KR",
      "code":"KR",
      "code_3":"KOR",
      "calling_codes":[
         "+82"
      ],
      "currencies":[
         "KRW"
      ],
      "timezones":[
         "Asia/Seoul"
      ]
   },
   {
      "name":"Kuwait",
      "flag":"KW",
      "code":"KW",
      "code_3":"KWT",
      "calling_codes":[
         "+965"
      ],
      "currencies":[
         "KWD"
      ],
      "timezones":[
         "Asia/Kuwait"
      ]
   },
   {
      "name":"Cayman Islands",
      "flag":"KY",
      "code":"KY",
      "code_3":"CYM",
      "calling_codes":[
         "+1 345"
      ],
      "currencies":[
         "KYD"
      ],
      "timezones":[
         "America/Cayman"
      ]
   },
   {
      "name":"Kazakhstan",
      "flag":"KZ",
      "code":"KZ",
      "code_3":"KAZ",
      "calling_codes":[
         "+7",
         "+7 6",
         "+7 7"
      ],
      "currencies":[
         "KZT"
      ],
      "timezones":[
         "Asia/Almaty",
         "Asia/Qyzylorda",
         "Asia/Aqtobe",
         "Asia/Aqtau",
         "Asia/Oral"
      ]
   },
   {
      "name":"Lao People's Democratic Republic",
      "flag":"LA",
      "code":"LA",
      "code_3":"LAO",
      "calling_codes":[
         "+856"
      ],
      "currencies":[
         "LAK"
      ],
      "timezones":[
         "Asia/Vientiane"
      ]
   },
   {
      "name":"Lebanon",
      "flag":"LB",
      "code":"LB",
      "code_3":"LBN",
      "calling_codes":[
         "+961"
      ],
      "currencies":[
         "LBP"
      ],
      "timezones":[
         "Asia/Beirut"
      ]
   },
   {
      "name":"Saint Lucia",
      "flag":"LC",
      "code":"LC",
      "code_3":"LCA",
      "calling_codes":[
         "+1 758"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "America/St_Lucia"
      ]
   },
   {
      "name":"Liechtenstein",
      "flag":"LI",
      "code":"LI",
      "code_3":"LIE",
      "calling_codes":[
         "+423"
      ],
      "currencies":[
         "CHF"
      ],
      "timezones":[
         "Europe/Vaduz"
      ]
   },
   {
      "name":"Sri Lanka",
      "flag":"LK",
      "code":"LK",
      "code_3":"LKA",
      "calling_codes":[
         "+94"
      ],
      "currencies":[
         "LKR"
      ],
      "timezones":[
         "Asia/Colombo"
      ]
   },
   {
      "name":"Liberia",
      "flag":"LR",
      "code":"LR",
      "code_3":"LBR",
      "calling_codes":[
         "+231"
      ],
      "currencies":[
         "LRD"
      ],
      "timezones":[
         "Africa/Monrovia"
      ]
   },
   {
      "name":"Lesotho",
      "flag":"LS",
      "code":"LS",
      "code_3":"LSO",
      "calling_codes":[
         "+266"
      ],
      "currencies":[
         "LSL"
      ],
      "timezones":[
         "Africa/Maseru"
      ]
   },
   {
      "name":"Lithuania",
      "flag":"LT",
      "code":"LT",
      "code_3":"LTU",
      "calling_codes":[
         "+370"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Vilnius"
      ]
   },
   {
      "name":"Luxembourg",
      "flag":"LU",
      "code":"LU",
      "code_3":"LUX",
      "calling_codes":[
         "+352"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Luxembourg"
      ]
   },
   {
      "name":"Latvia",
      "flag":"LV",
      "code":"LV",
      "code_3":"LVA",
      "calling_codes":[
         "+371"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Riga"
      ]
   },
   {
      "name":"Libya",
      "flag":"LY",
      "code":"LY",
      "code_3":"LBY",
      "calling_codes":[
         "+218"
      ],
      "currencies":[
         "LYD"
      ],
      "timezones":[
         "Africa/Tripoli"
      ]
   },
   {
      "name":"Morocco",
      "flag":"MA",
      "code":"MA",
      "code_3":"MAR",
      "calling_codes":[
         "+212"
      ],
      "currencies":[
         "MAD"
      ],
      "timezones":[
         "Africa/Casablanca"
      ]
   },
   {
      "name":"Monaco",
      "flag":"MC",
      "code":"MC",
      "code_3":"MCO",
      "calling_codes":[
         "+377"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Monaco"
      ]
   },
   {
      "name":"Moldova",
      "flag":"MD",
      "code":"MD",
      "code_3":"MDA",
      "calling_codes":[
         "+373"
      ],
      "currencies":[
         "MDL"
      ],
      "timezones":[
         "Europe/Chisinau"
      ]
   },
   {
      "name":"Montenegro",
      "flag":"ME",
      "code":"ME",
      "code_3":"MNE",
      "calling_codes":[
         "+382"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Podgorica"
      ]
   },
   {
      "name":"Saint Martin",
      "flag":"MF",
      "code":"MF",
      "code_3":"MAF",
      "calling_codes":[
         "+590"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "America/Marigot"
      ]
   },
   {
      "name":"Madagascar",
      "flag":"MG",
      "code":"MG",
      "code_3":"MDG",
      "calling_codes":[
         "+261"
      ],
      "currencies":[
         "MGA"
      ],
      "timezones":[
         "Indian/Antananarivo"
      ]
   },
   {
      "name":"Marshall Islands",
      "flag":"MH",
      "code":"MH",
      "code_3":"MHL",
      "calling_codes":[
         "+692"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Pacific/Majuro",
         "Pacific/Kwajalein"
      ]
   },
   {
      "name":"Macedonia, The Former Yugoslav Republic Of",
      "flag":"MK",
      "code":"MK",
      "code_3":"MKD",
      "calling_codes":[
         "+389"
      ],
      "currencies":[
         "MKD"
      ],
      "timezones":[
         "Europe/Skopje"
      ]
   },
   {
      "name":"Mali",
      "flag":"ML",
      "code":"ML",
      "code_3":"MLI",
      "calling_codes":[
         "+223"
      ],
      "currencies":[
         "XOF"
      ],
      "timezones":[
         "Africa/Bamako"
      ]
   },
   {
      "name":"Myanmar",
      "flag":"MM",
      "code":"MM",
      "code_3":"MMR",
      "calling_codes":[
         "+95"
      ],
      "currencies":[
         "MMK"
      ],
      "timezones":[
         "Asia/Rangoon"
      ]
   },
   {
      "name":"Mongolia",
      "flag":"MN",
      "code":"MN",
      "code_3":"MNG",
      "calling_codes":[
         "+976"
      ],
      "currencies":[
         "MNT"
      ],
      "timezones":[
         "Asia/Ulaanbaatar",
         "Asia/Hovd",
         "Asia/Choibalsan"
      ]
   },
   {
      "name":"Macao",
      "flag":"MO",
      "code":"MO",
      "code_3":"MAC",
      "calling_codes":[
         "+853"
      ],
      "currencies":[
         "MOP"
      ],
      "timezones":[
         "Asia/Macau"
      ]
   },
   {
      "name":"Northern Mariana Islands",
      "flag":"MP",
      "code":"MP",
      "code_3":"MNP",
      "calling_codes":[
         "+1 670"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Pacific/Saipan"
      ]
   },
   {
      "name":"Martinique",
      "flag":"MQ",
      "code":"MQ",
      "code_3":"MTQ",
      "calling_codes":[
         "+596"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "America/Martinique"
      ]
   },
   {
      "name":"Mauritania",
      "flag":"MR",
      "code":"MR",
      "code_3":"MRT",
      "calling_codes":[
         "+222"
      ],
      "currencies":[
         "MRO"
      ],
      "timezones":[
         "Africa/Nouakchott"
      ]
   },
   {
      "name":"Montserrat",
      "flag":"MS",
      "code":"MS",
      "code_3":"MSR",
      "calling_codes":[
         "+1 664"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "America/Montserrat"
      ]
   },
   {
      "name":"Malta",
      "flag":"MT",
      "code":"MT",
      "code_3":"MLT",
      "calling_codes":[
         "+356"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Malta"
      ]
   },
   {
      "name":"Mauritius",
      "flag":"MU",
      "code":"MU",
      "code_3":"MUS",
      "calling_codes":[
         "+230"
      ],
      "currencies":[
         "MUR"
      ],
      "timezones":[
         "Indian/Mauritius"
      ]
   },
   {
      "name":"Maldives",
      "flag":"MV",
      "code":"MV",
      "code_3":"MDV",
      "calling_codes":[
         "+960"
      ],
      "currencies":[
         "MVR"
      ],
      "timezones":[
         "Indian/Maldives"
      ]
   },
   {
      "name":"Malawi",
      "flag":"MW",
      "code":"MW",
      "code_3":"MWI",
      "calling_codes":[
         "+265"
      ],
      "currencies":[
         "MWK"
      ],
      "timezones":[
         "Africa/Blantyre"
      ]
   },
   {
      "name":"Mexico",
      "flag":"MX",
      "code":"MX",
      "code_3":"MEX",
      "calling_codes":[
         "+52"
      ],
      "currencies":[
         "MXN"
      ],
      "timezones":[
         "America/Mexico_City",
         "America/Cancun",
         "America/Merida",
         "America/Monterrey",
         "America/Matamoros",
         "America/Mazatlan",
         "America/Chihuahua",
         "America/Ojinaga",
         "America/Hermosillo",
         "America/Tijuana",
         "America/Bahia_Banderas"
      ]
   },
   {
      "name":"Malaysia",
      "flag":"MY",
      "code":"MY",
      "code_3":"MYS",
      "calling_codes":[
         "+60"
      ],
      "currencies":[
         "MYR"
      ],
      "timezones":[
         "Asia/Kuala_Lumpur",
         "Asia/Kuching"
      ]
   },
   {
      "name":"Mozambique",
      "flag":"MZ",
      "code":"MZ",
      "code_3":"MOZ",
      "calling_codes":[
         "+258"
      ],
      "currencies":[
         "MZN"
      ],
      "timezones":[
         "Africa/Maputo"
      ]
   },
   {
      "name":"Namibia",
      "flag":"NA",
      "code":"NA",
      "code_3":"NAM",
      "calling_codes":[
         "+264"
      ],
      "currencies":[
         "NAD"
      ],
      "timezones":[
         "Africa/Windhoek"
      ]
   },
   {
      "name":"New Caledonia",
      "flag":"NC",
      "code":"NC",
      "code_3":"NCL",
      "calling_codes":[
         "+687"
      ],
      "currencies":[
         "XPF"
      ],
      "timezones":[
         "Pacific/Noumea"
      ]
   },
   {
      "name":"Niger",
      "flag":"NE",
      "code":"NE",
      "code_3":"NER",
      "calling_codes":[
         "+227"
      ],
      "currencies":[
         "XOF"
      ],
      "timezones":[
         "Africa/Niamey"
      ]
   },
   {
      "name":"Norfolk Island",
      "flag":"NF",
      "code":"NF",
      "code_3":"NFK",
      "calling_codes":[
         "+672"
      ],
      "currencies":[
         "AUD"
      ],
      "timezones":[
         "Pacific/Norfolk"
      ]
   },
   {
      "name":"Nigeria",
      "flag":"NG",
      "code":"NG",
      "code_3":"NGA",
      "calling_codes":[
         "+234"
      ],
      "currencies":[
         "NGN"
      ],
      "timezones":[
         "Africa/Lagos"
      ]
   },
   {
      "name":"Nicaragua",
      "flag":"NI",
      "code":"NI",
      "code_3":"NIC",
      "calling_codes":[
         "+505"
      ],
      "currencies":[
         "NIO"
      ],
      "timezones":[
         "America/Managua"
      ]
   },
   {
      "name":"Netherlands",
      "flag":"NL",
      "code":"NL",
      "code_3":"NLD",
      "calling_codes":[
         "+31"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Amsterdam"
      ]
   },
   {
      "name":"Norway",
      "flag":"NO",
      "code":"NO",
      "code_3":"NOR",
      "calling_codes":[
         "+47"
      ],
      "currencies":[
         "NOK"
      ],
      "timezones":[
         "Europe/Oslo"
      ]
   },
   {
      "name":"Nepal",
      "flag":"NP",
      "code":"NP",
      "code_3":"NPL",
      "calling_codes":[
         "+977"
      ],
      "currencies":[
         "NPR"
      ],
      "timezones":[
         "Asia/Kathmandu"
      ]
   },
   {
      "name":"Nauru",
      "flag":"NR",
      "code":"NR",
      "code_3":"NRU",
      "calling_codes":[
         "+674"
      ],
      "currencies":[
         "AUD"
      ],
      "timezones":[
         "Pacific/Nauru"
      ]
   },
   {
      "name":"Niue",
      "flag":"NU",
      "code":"NU",
      "code_3":"NIU",
      "calling_codes":[
         "+683"
      ],
      "currencies":[
         "NZD"
      ],
      "timezones":[
         "Pacific/Niue"
      ]
   },
   {
      "name":"New Zealand",
      "flag":"NZ",
      "code":"NZ",
      "code_3":"NZL",
      "calling_codes":[
         "+64"
      ],
      "currencies":[
         "NZD"
      ],
      "timezones":[
         "Pacific/Auckland",
         "Pacific/Chatham"
      ]
   },
   {
      "name":"Oman",
      "flag":"OM",
      "code":"OM",
      "code_3":"OMN",
      "calling_codes":[
         "+968"
      ],
      "currencies":[
         "OMR"
      ],
      "timezones":[
         "Asia/Muscat"
      ]
   },
   {
      "name":"Panama",
      "flag":"PA",
      "code":"PA",
      "code_3":"PAN",
      "calling_codes":[
         "+507"
      ],
      "currencies":[
         "PAB"
      ],
      "timezones":[
         "America/Panama"
      ]
   },
   {
      "name":"Peru",
      "flag":"PE",
      "code":"PE",
      "code_3":"PER",
      "calling_codes":[
         "+51"
      ],
      "currencies":[
         "PEN"
      ],
      "timezones":[
         "America/Lima"
      ]
   },
   {
      "name":"French Polynesia",
      "flag":"PF",
      "code":"PF",
      "code_3":"PYF",
      "calling_codes":[
         "+689"
      ],
      "currencies":[
         "XPF"
      ],
      "timezones":[
         "Pacific/Tahiti",
         "Pacific/Marquesas",
         "Pacific/Gambier"
      ]
   },
   {
      "name":"Papua New Guinea",
      "flag":"PG",
      "code":"PG",
      "code_3":"PNG",
      "calling_codes":[
         "+675"
      ],
      "currencies":[
         "PGK"
      ],
      "timezones":[
         "Pacific/Port_Moresby",
         "Pacific/Bougainville"
      ]
   },
   {
      "name":"Philippines",
      "flag":"PH",
      "code":"PH",
      "code_3":"PHL",
      "calling_codes":[
         "+63"
      ],
      "currencies":[
         "PHP"
      ],
      "timezones":[
         "Asia/Manila"
      ]
   },
   {
      "name":"Pakistan",
      "flag":"PK",
      "code":"PK",
      "code_3":"PAK",
      "calling_codes":[
         "+92"
      ],
      "currencies":[
         "PKR"
      ],
      "timezones":[
         "Asia/Karachi"
      ]
   },
   {
      "name":"Poland",
      "flag":"PL",
      "code":"PL",
      "code_3":"POL",
      "calling_codes":[
         "+48"
      ],
      "currencies":[
         "PLN"
      ],
      "timezones":[
         "Europe/Warsaw"
      ]
   },
   {
      "name":"Saint Pierre And Miquelon",
      "flag":"PM",
      "code":"PM",
      "code_3":"SPM",
      "calling_codes":[
         "+508"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "America/Miquelon"
      ]
   },
   {
      "name":"Pitcairn",
      "flag":"PN",
      "code":"PN",
      "code_3":"PCN",
      "calling_codes":[
         "+872"
      ],
      "currencies":[
         "NZD"
      ],
      "timezones":[
         "Pacific/Pitcairn"
      ]
   },
   {
      "name":"Puerto Rico",
      "flag":"PR",
      "code":"PR",
      "code_3":"PRI",
      "calling_codes":[
         "+1 787",
         "+1 939"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "America/Puerto_Rico"
      ]
   },
   {
      "name":"Palestinian Territory, Occupied",
      "flag":"PS",
      "code":"PS",
      "code_3":"PSE",
      "calling_codes":[
         "+970"
      ],
      "currencies":[
         "ILS"
      ],
      "timezones":[
         "Asia/Gaza",
         "Asia/Hebron"
      ]
   },
   {
      "name":"Portugal",
      "flag":"PT",
      "code":"PT",
      "code_3":"PRT",
      "calling_codes":[
         "+351"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Lisbon",
         "Atlantic/Madeira",
         "Atlantic/Azores"
      ]
   },
   {
      "name":"Palau",
      "flag":"PW",
      "code":"PW",
      "code_3":"PLW",
      "calling_codes":[
         "+680"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Pacific/Palau"
      ]
   },
   {
      "name":"Paraguay",
      "flag":"PY",
      "code":"PY",
      "code_3":"PRY",
      "calling_codes":[
         "+595"
      ],
      "currencies":[
         "PYG"
      ],
      "timezones":[
         "America/Asuncion"
      ]
   },
   {
      "name":"Qatar",
      "flag":"QA",
      "code":"QA",
      "code_3":"QAT",
      "calling_codes":[
         "+974"
      ],
      "currencies":[
         "QAR"
      ],
      "timezones":[
         "Asia/Qatar"
      ]
   },
   {
      "name":"Reunion",
      "flag":"RE",
      "code":"RE",
      "code_3":"REU",
      "calling_codes":[
         "+262"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Indian/Reunion"
      ]
   },
   {
      "name":"Romania",
      "flag":"RO",
      "code":"RO",
      "code_3":"ROU",
      "calling_codes":[
         "+40"
      ],
      "currencies":[
         "RON"
      ],
      "timezones":[
         "Europe/Bucharest"
      ]
   },
   {
      "name":"Serbia",
      "flag":"RS",
      "code":"RS",
      "code_3":"SRB",
      "calling_codes":[
         "+381"
      ],
      "currencies":[
         "RSD"
      ],
      "timezones":[
         "Europe/Belgrade"
      ]
   },
   {
      "name":"Russian Federation",
      "flag":"RU",
      "code":"RU",
      "code_3":"RUS",
      "calling_codes":[
         "+7",
         "+7 3",
         "+7 4",
         "+7 8"
      ],
      "currencies":[
         "RUB"
      ],
      "timezones":[
         "Europe/Kaliningrad",
         "Europe/Moscow",
         "Europe/Simferopol",
         "Europe/Volgograd",
         "Europe/Kirov",
         "Europe/Astrakhan",
         "Europe/Samara",
         "Europe/Ulyanovsk",
         "Asia/Yekaterinburg",
         "Asia/Omsk",
         "Asia/Novosibirsk",
         "Asia/Barnaul",
         "Asia/Tomsk",
         "Asia/Novokuznetsk",
         "Asia/Krasnoyarsk",
         "Asia/Irkutsk",
         "Asia/Chita",
         "Asia/Yakutsk",
         "Asia/Khandyga",
         "Asia/Vladivostok",
         "Asia/Ust-Nera",
         "Asia/Magadan",
         "Asia/Sakhalin",
         "Asia/Srednekolymsk",
         "Asia/Kamchatka",
         "Asia/Anadyr"
      ]
   },
   {
      "name":"Rwanda",
      "flag":"RW",
      "code":"RW",
      "code_3":"RWA",
      "calling_codes":[
         "+250"
      ],
      "currencies":[
         "RWF"
      ],
      "timezones":[
         "Africa/Kigali"
      ]
   },
   {
      "name":"Saudi Arabia",
      "flag":"SA",
      "code":"SA",
      "code_3":"SAU",
      "calling_codes":[
         "+966"
      ],
      "currencies":[
         "SAR"
      ],
      "timezones":[
         "Asia/Riyadh"
      ]
   },
   {
      "name":"Solomon Islands",
      "flag":"SB",
      "code":"SB",
      "code_3":"SLB",
      "calling_codes":[
         "+677"
      ],
      "currencies":[
         "SBD"
      ],
      "timezones":[
         "Pacific/Guadalcanal"
      ]
   },
   {
      "name":"Seychelles",
      "flag":"SC",
      "code":"SC",
      "code_3":"SYC",
      "calling_codes":[
         "+248"
      ],
      "currencies":[
         "SCR"
      ],
      "timezones":[
         "Indian/Mahe"
      ]
   },
   {
      "name":"Sudan",
      "flag":"SD",
      "code":"SD",
      "code_3":"SDN",
      "calling_codes":[
         "+249"
      ],
      "currencies":[
         "SDG"
      ],
      "timezones":[
         "Africa/Khartoum"
      ]
   },
   {
      "name":"Sweden",
      "flag":"SE",
      "code":"SE",
      "code_3":"SWE",
      "calling_codes":[
         "+46"
      ],
      "currencies":[
         "SEK"
      ],
      "timezones":[
         "Europe/Stockholm"
      ]
   },
   {
      "name":"Singapore",
      "flag":"SG",
      "code":"SG",
      "code_3":"SGP",
      "calling_codes":[
         "+65"
      ],
      "currencies":[
         "SGD"
      ],
      "timezones":[
         "Asia/Singapore"
      ]
   },
   {
      "name":"Saint Helena, Ascension And Tristan Da Cunha",
      "flag":"SH",
      "code":"SH",
      "code_3":"SHN",
      "calling_codes":[
         "+290"
      ],
      "currencies":[
         "SHP"
      ],
      "timezones":[
         "Atlantic/St_Helena"
      ]
   },
   {
      "name":"Slovenia",
      "flag":"SI",
      "code":"SI",
      "code_3":"SVN",
      "calling_codes":[
         "+386"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Ljubljana"
      ]
   },
   {
      "name":"Svalbard And Jan Mayen",
      "flag":"SJ",
      "code":"SJ",
      "code_3":"SJM",
      "calling_codes":[
         "+47"
      ],
      "currencies":[
         "NOK"
      ],
      "timezones":[
         "Arctic/Longyearbyen"
      ]
   },
   {
      "name":"Slovakia",
      "flag":"SK",
      "code":"SK",
      "code_3":"SVK",
      "calling_codes":[
         "+421"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Bratislava"
      ]
   },
   {
      "name":"Sierra Leone",
      "flag":"SL",
      "code":"SL",
      "code_3":"SLE",
      "calling_codes":[
         "+232"
      ],
      "currencies":[
         "SLL"
      ],
      "timezones":[
         "Africa/Freetown"
      ]
   },
   {
      "name":"San Marino",
      "flag":"SM",
      "code":"SM",
      "code_3":"SMR",
      "calling_codes":[
         "+378"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/San_Marino"
      ]
   },
   {
      "name":"Senegal",
      "flag":"SN",
      "code":"SN",
      "code_3":"SEN",
      "calling_codes":[
         "+221"
      ],
      "currencies":[
         "XOF"
      ],
      "timezones":[
         "Africa/Dakar"
      ]
   },
   {
      "name":"Somalia",
      "flag":"SO",
      "code":"SO",
      "code_3":"SOM",
      "calling_codes":[
         "+252"
      ],
      "currencies":[
         "SOS"
      ],
      "timezones":[
         "Africa/Mogadishu"
      ]
   },
   {
      "name":"Suriname",
      "flag":"SR",
      "code":"SR",
      "code_3":"SUR",
      "calling_codes":[
         "+597"
      ],
      "currencies":[
         "SRD"
      ],
      "timezones":[
         "America/Paramaribo"
      ]
   },
   {
      "name":"South Sudan",
      "flag":"SS",
      "code":"SS",
      "code_3":"SSD",
      "calling_codes":[
         "+211"
      ],
      "currencies":[
         "SSP"
      ],
      "timezones":[
         "Africa/Juba"
      ]
   },
   {
      "name":"Sao Tome and Principe",
      "flag":"ST",
      "code":"ST",
      "code_3":"STP",
      "calling_codes":[
         "+239"
      ],
      "currencies":[
         "STD"
      ],
      "timezones":[
         "Africa/Sao_Tome"
      ]
   },
   {
      "name":"El Salvador",
      "flag":"SV",
      "code":"SV",
      "code_3":"SLV",
      "calling_codes":[
         "+503"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "America/El_Salvador"
      ]
   },
   {
      "name":"Sint Maarten",
      "flag":"SX",
      "code":"SX",
      "code_3":"SXM",
      "calling_codes":[
         "+1 721"
      ],
      "currencies":[
         "ANG"
      ],
      "timezones":[
         "America/Lower_Princes"
      ]
   },
   {
      "name":"Syrian Arab Republic",
      "flag":"SY",
      "code":"SY",
      "code_3":"SYR",
      "calling_codes":[
         "+963"
      ],
      "currencies":[
         "SYP"
      ],
      "timezones":[
         "Asia/Damascus"
      ]
   },
   {
      "name":"Swaziland",
      "flag":"SZ",
      "code":"SZ",
      "code_3":"SWZ",
      "calling_codes":[
         "+268"
      ],
      "currencies":[
         "SZL"
      ],
      "timezones":[
         "Africa/Mbabane"
      ]
   },
   {
      "name":"Tristan de Cunha",
      "flag":"TA",
      "code":"TA",
      "code_3":"",
      "calling_codes":[
         "+290"
      ],
      "currencies":[
         "GBP"
      ],
      "timezones":[
         "Atlantic/St_Helena"
      ]
   },
   {
      "name":"Turks And Caicos Islands",
      "flag":"TC",
      "code":"TC",
      "code_3":"TCA",
      "calling_codes":[
         "+1 649"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "America/Grand_Turk"
      ]
   },
   {
      "name":"Chad",
      "flag":"TD",
      "code":"TD",
      "code_3":"TCD",
      "calling_codes":[
         "+235"
      ],
      "currencies":[
         "XAF"
      ],
      "timezones":[
         "Africa/Ndjamena"
      ]
   },
   {
      "name":"Togo",
      "flag":"TG",
      "code":"TG",
      "code_3":"TGO",
      "calling_codes":[
         "+228"
      ],
      "currencies":[
         "XOF"
      ],
      "timezones":[
         "Africa/Lome"
      ]
   },
   {
      "name":"Thailand",
      "flag":"TH",
      "code":"TH",
      "code_3":"THA",
      "calling_codes":[
         "+66"
      ],
      "currencies":[
         "THB"
      ],
      "timezones":[
         "Asia/Bangkok"
      ]
   },
   {
      "name":"Tajikistan",
      "flag":"TJ",
      "code":"TJ",
      "code_3":"TJK",
      "calling_codes":[
         "+992"
      ],
      "currencies":[
         "TJS"
      ],
      "timezones":[
         "Asia/Dushanbe"
      ]
   },
   {
      "name":"Tokelau",
      "flag":"TK",
      "code":"TK",
      "code_3":"TKL",
      "calling_codes":[
         "+690"
      ],
      "currencies":[
         "NZD"
      ],
      "timezones":[
         "Pacific/Fakaofo"
      ]
   },
   {
      "name":"Timor-Leste, Democratic Republic of",
      "flag":"TL",
      "code":"TL",
      "code_3":"TLS",
      "calling_codes":[
         "+670"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Asia/Dili"
      ]
   },
   {
      "name":"Turkmenistan",
      "flag":"TM",
      "code":"TM",
      "code_3":"TKM",
      "calling_codes":[
         "+993"
      ],
      "currencies":[
         "TMT"
      ],
      "timezones":[
         "Asia/Ashgabat"
      ]
   },
   {
      "name":"Tunisia",
      "flag":"TN",
      "code":"TN",
      "code_3":"TUN",
      "calling_codes":[
         "+216"
      ],
      "currencies":[
         "TND"
      ],
      "timezones":[
         "Africa/Tunis"
      ]
   },
   {
      "name":"Tonga",
      "flag":"TO",
      "code":"TO",
      "code_3":"TON",
      "calling_codes":[
         "+676"
      ],
      "currencies":[
         "TOP"
      ],
      "timezones":[
         "Pacific/Tongatapu"
      ]
   },
   {
      "name":"Turkey",
      "flag":"TR",
      "code":"TR",
      "code_3":"TUR",
      "calling_codes":[
         "+90"
      ],
      "currencies":[
         "TRY"
      ],
      "timezones":[
         "Europe/Istanbul"
      ]
   },
   {
      "name":"Trinidad And Tobago",
      "flag":"TT",
      "code":"TT",
      "code_3":"TTO",
      "calling_codes":[
         "+1 868"
      ],
      "currencies":[
         "TTD"
      ],
      "timezones":[
         "America/Port_of_Spain"
      ]
   },
   {
      "name":"Tuvalu",
      "flag":"TV",
      "code":"TV",
      "code_3":"TUV",
      "calling_codes":[
         "+688"
      ],
      "currencies":[
         "AUD"
      ],
      "timezones":[
         "Pacific/Funafuti"
      ]
   },
   {
      "name":"Taiwan",
      "flag":"TW",
      "code":"TW",
      "code_3":"TWN",
      "calling_codes":[
         "+886"
      ],
      "currencies":[
         "TWD"
      ],
      "timezones":[
         "Asia/Taipei"
      ]
   },
   {
      "name":"Tanzania, United Republic Of",
      "flag":"TZ",
      "code":"TZ",
      "code_3":"TZA",
      "calling_codes":[
         "+255"
      ],
      "currencies":[
         "TZS"
      ],
      "timezones":[
         "Africa/Dar_es_Salaam"
      ]
   },
   {
      "name":"Ukraine",
      "flag":"UA",
      "code":"UA",
      "code_3":"UKR",
      "calling_codes":[
         "+380"
      ],
      "currencies":[
         "UAH"
      ],
      "timezones":[
         "Europe/Kiev",
         "Europe/Uzhgorod",
         "Europe/Zaporozhye"
      ]
   },
   {
      "name":"Uganda",
      "flag":"UG",
      "code":"UG",
      "code_3":"UGA",
      "calling_codes":[
         "+256"
      ],
      "currencies":[
         "UGX"
      ],
      "timezones":[
         "Africa/Kampala"
      ]
   },
   {
      "name":"United States Minor Outlying Islands",
      "flag":"UM",
      "code":"UM",
      "code_3":"UMI",
      "calling_codes":[
         "+1"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "Pacific/Johnston",
         "Pacific/Midway",
         "Pacific/Wake"
      ]
   },
   {
      "name":"United States",
      "flag":"US",
      "code":"US",
      "code_3":"USA",
      "calling_codes":[
         "+1"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "America/New_York",
         "America/Detroit",
         "America/Kentucky/Louisville",
         "America/Kentucky/Monticello",
         "America/Indiana/Indianapolis",
         "America/Indiana/Vincennes",
         "America/Indiana/Winamac",
         "America/Indiana/Marengo",
         "America/Indiana/Petersburg",
         "America/Indiana/Vevay",
         "America/Chicago",
         "America/Indiana/Tell_City",
         "America/Indiana/Knox",
         "America/Menominee",
         "America/North_Dakota/Center",
         "America/North_Dakota/New_Salem",
         "America/North_Dakota/Beulah",
         "America/Denver",
         "America/Boise",
         "America/Phoenix",
         "America/Los_Angeles",
         "America/Anchorage",
         "America/Juneau",
         "America/Sitka",
         "America/Metlakatla",
         "America/Yakutat",
         "America/Nome",
         "America/Adak",
         "Pacific/Honolulu"
      ]
   },
   {
      "name":"Uruguay",
      "flag":"UY",
      "code":"UY",
      "code_3":"URY",
      "calling_codes":[
         "+598"
      ],
      "currencies":[
         "UYU"
      ],
      "timezones":[
         "America/Montevideo"
      ]
   },
   {
      "name":"Uzbekistan",
      "flag":"UZ",
      "code":"UZ",
      "code_3":"UZB",
      "calling_codes":[
         "+998"
      ],
      "currencies":[
         "UZS"
      ],
      "timezones":[
         "Asia/Samarkand",
         "Asia/Tashkent"
      ]
   },
   {
      "name":"Vatican City State",
      "flag":"VA",
      "code":"VA",
      "code_3":"VAT",
      "calling_codes":[
         "+379",
         "+39"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Vatican"
      ]
   },
   {
      "name":"Saint Vincent And The Grenadines",
      "flag":"VC",
      "code":"VC",
      "code_3":"VCT",
      "calling_codes":[
         "+1 784"
      ],
      "currencies":[
         "XCD"
      ],
      "timezones":[
         "America/St_Vincent"
      ]
   },
   {
      "name":"Venezuela, Bolivarian Republic Of",
      "flag":"VE",
      "code":"VE",
      "code_3":"VEN",
      "calling_codes":[
         "+58"
      ],
      "currencies":[
         "VEF"
      ],
      "timezones":[
         "America/Caracas"
      ]
   },
   {
      "name":"Virgin Islands (British)",
      "flag":"VG",
      "code":"VG",
      "code_3":"VGB",
      "calling_codes":[
         "+1 284"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "America/Tortola"
      ]
   },
   {
      "name":"Virgin Islands (US)",
      "flag":"VI",
      "code":"VI",
      "code_3":"VIR",
      "calling_codes":[
         "+1 340"
      ],
      "currencies":[
         "USD"
      ],
      "timezones":[
         "America/St_Thomas"
      ]
   },
   {
      "name":"Viet Nam",
      "flag":"VN",
      "code":"VN",
      "code_3":"VNM",
      "calling_codes":[
         "+84"
      ],
      "currencies":[
         "VND"
      ],
      "timezones":[
         "Asia/Ho_Chi_Minh"
      ]
   },
   {
      "name":"Vanuatu",
      "flag":"VU",
      "code":"VU",
      "code_3":"VUT",
      "calling_codes":[
         "+678"
      ],
      "currencies":[
         "VUV"
      ],
      "timezones":[
         "Pacific/Efate"
      ]
   },
   {
      "name":"Wallis And Futuna",
      "flag":"WF",
      "code":"WF",
      "code_3":"WLF",
      "calling_codes":[
         "+681"
      ],
      "currencies":[
         "XPF"
      ],
      "timezones":[
         "Pacific/Wallis"
      ]
   },
   {
      "name":"Samoa",
      "flag":"WS",
      "code":"WS",
      "code_3":"WSM",
      "calling_codes":[
         "+685"
      ],
      "currencies":[
         "WST"
      ],
      "timezones":[
         "Pacific/Apia"
      ]
   },
   {
      "name":"Kosovo",
      "flag":"XK",
      "code":"XK",
      "code_3":"",
      "calling_codes":[
         "+383"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Europe/Belgrade"
      ]
   },
   {
      "name":"Yemen",
      "flag":"YE",
      "code":"YE",
      "code_3":"YEM",
      "calling_codes":[
         "+967"
      ],
      "currencies":[
         "YER"
      ],
      "timezones":[
         "Asia/Aden"
      ]
   },
   {
      "name":"Mayotte",
      "flag":"YT",
      "code":"YT",
      "code_3":"MYT",
      "calling_codes":[
         "+262"
      ],
      "currencies":[
         "EUR"
      ],
      "timezones":[
         "Indian/Mayotte"
      ]
   },
   {
      "name":"South Africa",
      "flag":"ZA",
      "code":"ZA",
      "code_3":"ZAF",
      "calling_codes":[
         "+27"
      ],
      "currencies":[
         "ZAR"
      ],
      "timezones":[
         "Africa/Johannesburg"
      ]
   },
   {
      "name":"Zambia",
      "flag":"ZM",
      "code":"ZM",
      "code_3":"ZMB",
      "calling_codes":[
         "+260"
      ],
      "currencies":[
         "ZMW"
      ],
      "timezones":[
         "Africa/Lusaka"
      ]
   },
   {
      "name":"Zimbabwe",
      "flag":"ZW",
      "code":"ZW",
      "code_3":"ZWE",
      "calling_codes":[
         "+263"
      ],
      "currencies":[
         "ZWD"
      ],
      "timezones":[
         "Africa/Harare"
      ]
   }
];