<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left" style="flex-basis: 60%;">
    
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
      href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>


    
    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        
        <span>
          <img alt="Profile Picture" (error)="$event.target.src = DEFAULT_IMAGE" [src]="store.image_url === '' ? '/assets/img/default_images/user.png' : IMAGE_URL + store.image_url" />
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a routerLink="/app/profile" class="dropdown-item c-pointer">Account</a>
        <!--<a routerLink="/app/offers" class="dropdown-item c-pointer">Offers</a>
        <a routerLink="/app/history/history-list" class="dropdown-item c-pointer">History</a>
         <a class="dropdown-item c-pointer">Feedback</a> -->
        <li class="divider dropdown-divider"></li>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">Sign out</a>
      </div>
    </div>
    <div class="name ml-2 text-white" style="margin-top: -1px;font-size: 40px;color:white !important;font-family: RoundedFont;height: 55px;overflow: hidden;">{{displayName.toUpperCase()}}</div>
    <span (click)="openCheckList()" class="badge badge-pill badge-outline-primary mt-4 mb-1 c-pointer" style="background: white;  margin-left: 15px;margin-bottom: 25px !important;height: 30px;line-height: 0.6;font-size: 17px;" *ngIf="store.is_approved && !store.is_go_live">Apply to  Go-Live</span>
    <!-- <a class="btn btn-sm btn-outline-primary ml-2 d-none d-xl-inline-block" [href]="buyUrl"
      target="_blank">&nbsp;BUY&nbsp;</a> -->
  </div>

  <!-- <a class="navbar-logo" [routerLink]="adminRoot" >
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a> -->

  <div class="navbar-right pr-4">
    <div class="header-icons d-inline-block align-middle">

      <!-- <div class="d-none d-md-inline-block align-text-bottom mr-1" *ngIf="store">
        <div class="custom-control custom-switch" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="store.is_business"
            (change)="businessChange(!store.is_business)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div> -->


      <!-- <div class="position-relative d-none d-sm-inline-block">
        <div dropdown>
          <button dropdownToggle type="button" class="header-icon btn btn-empty">
            <i class="simple-icon-grid"></i>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic"
            id="iconMenuDropdown">
            <a routerLink="/app/order/today-orders" href="javascript:;" class="icon-menu-item">
              <i class="simple-icon-notebook d-block"></i>
              <span>Order</span>
            </a>

            <a routerLink="/app/earning/current-week" href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-coins d-block"></i>
              <span>Earning</span>
            </a>

            <a routerLink="/app/settings/store-settings" href="javascript:;" class="icon-menu-item">
              <i class="simple-icon-settings d-block"></i>
              <span>Setting</span>
            </a>

            <a routerLink="/app/menu/item-list" href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-pantone d-block"></i>
              <span>Menu</span>
            </a>

          </div>
        </div>
      </div> -->

      <!-- <div class="position-relative d-inline-block">

        <div dropdown>
          <button id="button-basic" dropdownToggle type="button" id="notificationButton"
            class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count">3</span>
          </button>
          <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3"
            id="notificationDropdown">
            <perfect-scrollbar>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/profiles/l-2.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/notifications/thumb-1.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">1 item is out of stock!</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                  <img src="/assets/img/notifications/thumb-2.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">New order received! It is total $147,20.</p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
              <div class="d-flex flex-row mb-3 pb-3 ">
                <a href="javascript:;">
                  <img src="/assets/img/notifications/thumb-3.jpg" alt="Notification Image"
                    class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">3 items just added to wish list by a user!
                    </p>
                    <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                  </a>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div> -->

      <!--<button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
        (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>-->

    </div>
      <!-- <div class="btn-group ml-2 pr-3" style="height: 30px;" dropdown *ngIf="!isSingleLang">
      <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
        {{this.langService.languageShorthand | uppercase}} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item"
            href="javascript:;">{{item.label}}</a></li>
      </ul>
    </div> -->
          <img alt="Profile Picture" class="profile-picture pl-4 Asset3" src="/assets/logos/Asset3.png" style="padding-right: 35px;height: 80px;max-height: unset" />
      <span style="position: absolute;
    right: 0;
    bottom: -29px;
    color: red;
    background: white;
    padding: 5px;">{{bank_detail}}</span>

  </div>
</nav>


<ng-template #template1>
  <div class="modal-header">
      <h4 class="modal-title pull-left">Restaurnat Go-Live Checklist</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef1.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
    <div>
      To apply to go-live on Deliver Angel, you'll need to have completed your set-up. Please use this checklist below to make sure you're ready to go before applying:
    </div>
    <br><br>

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check1" class="custom-control-input" (change)="update_url('check1')">
        <label for="check1" class="custom-control-label">Hero image added?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check2" class="custom-control-input" (change)="update_url('check2')">
        <label for="check2" class="custom-control-label">General Settings complete?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check3" class="custom-control-input" (change)="update_url('check3')">
        <label for="check3" class="custom-control-label">Delivery Settings complete?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check4" class="custom-control-input" (change)="update_url('check4')">
        <label for="check4" class="custom-control-label">Busy Period Settings complete?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check5" class="custom-control-input" (change)="update_url('check5')">
        <label for="check5" class="custom-control-label">Trading Hours set?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check6" class="custom-control-input" (change)="update_url('check6')">
        <label for="check6" class="custom-control-label">Prep time Settings complete?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check7" class="custom-control-input" (change)="update_url('check7')">
        <label for="check7" class="custom-control-label">Menu created & images loaded?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check8" class="custom-control-input" (change)="update_url('check8')">
        <label for="check8" class="custom-control-label">Modifiers added to Menu Items?</label>
      </div>
    </div><hr style="margin: 5px;">
    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check9" class="custom-control-input" (change)="update_url('check9')">
        <label for="check9" class="custom-control-label">Restaurant App login set-up under Team?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check10" class="custom-control-input" (change)="update_url('check10')">
        <label for="check10" class="custom-control-label">Restaurant App dounloaded and printer connected?</label>
      </div>
    </div><hr style="margin: 5px;">

    <div class="col-12">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" id="check11" class="custom-control-input" (change)="update_url('check11')">
        <label for="check11" class="custom-control-label">Have all your favourite drivers onboarded with Rolla?</label>
      </div>
    </div><hr style="margin: 5px;"><br>

    <div *ngIf="checklist.includes('check11')" style="color: #D9027D;">
      Your favourite drivers can also join Rolla so they can help fulfill your Delivery Angel orders. 
      The more of your regular drivers that you help onboard with Rolla, the smoother your delivery service will be for your customers.
    </div>

  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="modalRef1.hide()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="onConfirm()" [disabled]="checklist.length != 11">Apply</button>
  </div>

</ng-template>