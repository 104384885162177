export var CONFIG = {
  // apiUrl: 'https://api.deliveryangel.com',
  // imageUrl: 'https://api.deliveryangel.com/',

  // apiUrl: 'http://localhost:8000',
  // imageUrl: 'http://localhost:8000/',
  // apiUrl: 'http://192.168.0.118:8000',
  // imageUrl: 'http://192.168.0.118:8000/',
  //   apiUrl: 'https://8a23-103-190-6-162.ngrok-free.app',
  // imageUrl: 'https://8a23-103-190-6-162.ngrok-free.app/',


  apiUrl: 'https://stagingapi.deliveryangel.com',
  imageUrl: 'https://stagingapi.deliveryangel.com/',
};
